import 'phaser'
import { Connection, TrafficWindow } from '../../../modeles/lines'
import { colorLine, ColorPhaser } from '../../../modeles/color'
import { BehaviorSubject } from 'rxjs'
import { AbstractGroupElement } from '../abstract.group.element'
import { PlatformDisplayContainer } from '../time-display/time.display.element'
import { PLATFORM_NUMBER_TEXT_FONT, PLATFORM_TEXT_SIZE } from 'src/phaser/configs/configuration.text'
import { TrackBehavior } from './track.behavior.modele'
import {
  FIRST_CDV_GAP,
  getPlatformNameLength,
  getPosition,
  levelCalculation,
  LINE_GAP,
  positionFromNewContainer,
  selectInformationFromElementToDisplay,
  setPlateFormType,
} from './track.service'
import { PlatformStateType } from '../../../../app/core/models/platform-info-traffic'
import RoundRectangle from 'phaser3-rex-plugins/plugins/roundrectangle.js'
import { Scene } from 'phaser'
import GameObject = Phaser.GameObjects.GameObject
import {
  PLATFORM_LOOP_HEIGHT_SIZE,
  PLATFORM_LOOP_WIDTH_SIZE,
  PLATFORM_TEXT_Y_OFFSET,
  PLATFORM_SIZE,
  PLATFORM_CORRESPONDANCE_X_OFFSET,
  PLATFORM_CORRESPONDANCE_WIDTH,
  PLATFORM_CORRESPONDANCE_HEIGHT,
  PLATFORM_CORRESPONDANCE_Y_OFFSET,
} from './track.view.config'
import { LiveEventElement } from '../liveEvent/liveEvent.element'
import { UserPreferences } from '../../../../app/core/configurations/user/user.preferences'

export class TrackElement extends Phaser.GameObjects.Image {
  private readonly line: string

  constructor(scene: Phaser.Scene, private window: TrafficWindow, y: number, line: string) {
    super(scene, 0, y, setPlateFormType(window))
    this.line = line
    this.setColorOfElementIfNotTrackSwitch()
    this.setOrigin(0, 0.5)
    if (window.type === 'FIRST_CDV') {
      this.setX(FIRST_CDV_GAP)
    }
  }

  public setColorOfElementIfNotTrackSwitch() {
    this.tint = colorLine.filter((c) => c.id === this.line)[0].color
  }
}

export function TrackElementCorrespondance(scene: Scene, y: number, element: TrafficWindow, newLineMappingConstructionMethod: boolean): RoundRectangle {
  const stroke = 2
  const radius = 4
  const color = ColorPhaser.WHITE
  const width = PLATFORM_CORRESPONDANCE_WIDTH + getPlatformNameLength(element) * 5
  const height = PLATFORM_CORRESPONDANCE_HEIGHT
  const xPosition = 23
  const yPosition = 24 + y
  const newTrackElementBackground = new RoundRectangle(scene, xPosition, yPosition, width, height, radius)
  newTrackElementBackground.setFillStyle(color)
  newTrackElementBackground.setStrokeStyle(stroke, ColorPhaser.STATION_NAME)
  if (!newLineMappingConstructionMethod) {
    if (element.type === 'LOOP_WITH_PLATFORM') {
      newTrackElementBackground.setX(newTrackElementBackground.x + PLATFORM_CORRESPONDANCE_X_OFFSET)
    }
    if (element.track === 1) {
      if (element.type === 'LOOP_WITH_PLATFORM') {
        newTrackElementBackground.setY(newTrackElementBackground.y - (PLATFORM_TEXT_Y_OFFSET + PLATFORM_LOOP_HEIGHT_SIZE) + 44)
      }
      newTrackElementBackground.setY(newTrackElementBackground.y + PLATFORM_TEXT_Y_OFFSET)
    } else {
      newTrackElementBackground.setY(newTrackElementBackground.y - PLATFORM_CORRESPONDANCE_Y_OFFSET)
    }
  } else {
    newTrackElementBackground.setY(newTrackElementBackground.y - 16)
  }
  return newTrackElementBackground
}

export class TrackElementText extends Phaser.GameObjects.BitmapText {
  constructor(scene: Phaser.Scene, y: number, element: TrafficWindow, newLineMappingConstructionMethod: boolean) {
    super(scene, 0, y, PLATFORM_NUMBER_TEXT_FONT, selectInformationFromElementToDisplay(element), PLATFORM_TEXT_SIZE)
    if (!newLineMappingConstructionMethod) {
      this.setOrigin(0, this.setTextPositionDisplayed(element))
      if (element.track === 1) {
        if (element.type === 'LOOP_WITH_PLATFORM') {
          this.setY(this.y - (PLATFORM_TEXT_Y_OFFSET + PLATFORM_LOOP_HEIGHT_SIZE) + 44)
        }
        this.setY(this.y + PLATFORM_TEXT_Y_OFFSET)
      } else {
        this.setY(this.y - PLATFORM_TEXT_Y_OFFSET)
      }
    }
    this.setTint(ColorPhaser.STATION_NAME)
    if (element.type === 'LOOP_WITH_PLATFORM') {
      this.setX(this.x + PLATFORM_LOOP_WIDTH_SIZE)
    } else {
      this.setX(this.x - (this.width - PLATFORM_SIZE) / 2)
    }

  }

  private setTextPositionDisplayed(windows: TrafficWindow) {
    return windows.track === 1 ? -1 : 2
  }
}

export class ContainerTrackElement extends Phaser.GameObjects.Container {
  private readonly _id: string
  private readonly _platformTimeId: string
  private readonly _track: number
  private readonly _platformName: string
  private readonly _connections: Connection[]
  private readonly _ciClub: boolean
  private readonly _management: string
  private readonly _positionYOfCdv: number
  private readonly _platformLinkedCdv: string[]
  private readonly _platformDouble: string
  private _trackElement: TrackElement
  private _platformState: PlatformStateType
  private _isReferentialStation: boolean
  private _liveEventElement: LiveEventElement | null

  constructor(scene: Phaser.Scene,
              trafficWindow: TrafficWindow,
              line: string,
              userPreferences: UserPreferences,
              newLineMappingConstructionMethod: boolean,
              showStationName?: boolean,
              positionXObs?: BehaviorSubject<TrackBehavior>,
              positionX?: number,
              positionY?: number) {

    let positionYOfCdv = 0
    if (newLineMappingConstructionMethod) {
      super(scene, positionX, 0)
      positionYOfCdv = this.buildContainerTrackChildren(scene, positionYOfCdv, positionY, trafficWindow, line, userPreferences, newLineMappingConstructionMethod)
    } else {
      super(scene, getPosition(trafficWindow, positionXObs), 0)
      positionYOfCdv = this.buildContainerTrackChildrenOld(trafficWindow, positionXObs, positionYOfCdv, scene, line, showStationName, userPreferences, newLineMappingConstructionMethod)
    }

    this._positionYOfCdv = positionYOfCdv
    this._id = trafficWindow.id
    this._platformTimeId = trafficWindow.platformTimeId
    this._track = trafficWindow.track
    this._platformName = trafficWindow.platFormName
    this._connections = trafficWindow.connections
    this._ciClub = trafficWindow.ciClub
    this._management = trafficWindow.management
    this._platformState = PlatformStateType.OPEN
    this._platformLinkedCdv = trafficWindow.platformLinkedCdv
    this._platformDouble = trafficWindow.platformDouble
    this._isReferentialStation = false

  }

  private buildContainerTrackChildrenOld(trafficWindow: TrafficWindow, positionXObs: BehaviorSubject<TrackBehavior>, positionYOfCdv, scene: Phaser.Scene, line: string, showStationName: boolean, userPreferences: UserPreferences, newLineMappingConstructionMethod: boolean) {
    /**
     * create new padding from new element created
     */
    const level = levelCalculation(trafficWindow)
    const offsetY = positionXObs.getValue().offsetY
    positionYOfCdv = trafficWindow.track === 1 ? LINE_GAP - level + offsetY : -level + offsetY
    const positionYOfText = trafficWindow.track === 1 ? LINE_GAP - level + offsetY + 30 : -level + offsetY - 30
    let positionYOfPlatformDisplay
    if (trafficWindow.track === 1 && trafficWindow.type === 'LOOP_WITH_PLATFORM') {
      positionYOfPlatformDisplay = LINE_GAP - level + 38 + offsetY
    } else {
      positionYOfPlatformDisplay = trafficWindow.track === 1 ? LINE_GAP - level + 20 + offsetY : -level - 40 + offsetY
    }
    const children = this.buildChildren(trafficWindow, scene, positionYOfCdv, line, positionYOfText, positionYOfPlatformDisplay, showStationName, userPreferences, newLineMappingConstructionMethod)
    this.add(children)
    positionXObs.next(Object.assign(positionXObs.value, positionFromNewContainer(trafficWindow, positionXObs)))
    this.setPlatformInteractive(trafficWindow, positionYOfCdv, scene, positionYOfText, showStationName, newLineMappingConstructionMethod, positionYOfPlatformDisplay)
    return positionYOfCdv
  }

  private buildContainerTrackChildren(scene: Phaser.Scene, positionYOfCdv, positionY: number, trafficWindow: TrafficWindow, line: string, userPreferences: UserPreferences, newLineMappingConstructionMethod: boolean) {
    let positionYOfPlatformDisplay
    let positionYOfText
    positionYOfCdv = positionY
    if (trafficWindow.textPosition === 'BOTTOM') {
      positionYOfPlatformDisplay = positionY + 15
      positionYOfText = positionY + 50
    } else if (trafficWindow.textPosition === 'TOP') {
      positionYOfPlatformDisplay = positionY - 35
      positionYOfText = positionY - 60
    }
    const children = this.buildChildren(
      trafficWindow,
      scene,
      positionY,
      line,
      positionYOfText,
      positionYOfPlatformDisplay,
      trafficWindow.showStationName,
      userPreferences,
      newLineMappingConstructionMethod)
    this.add(children)
    this.setPlatformInteractive(trafficWindow, positionYOfCdv, scene, positionYOfText, trafficWindow.showStationName, newLineMappingConstructionMethod, positionYOfPlatformDisplay)
    return positionYOfCdv
  }
  /**
   * on rend interactif un quai
   */
  private setPlatformInteractive(trafficWindow: TrafficWindow,
                                 positionYOfCdv: number,
                                 scene: Phaser.Scene,
                                 positionYOfText: number,
                                 showStationName: boolean,
                                 newLineMappingConstructionMethod: boolean,
                                 positionYOfPlatformDisplay: number) {
    if (!!trafficWindow.platFormName && trafficWindow.platFormName !== 'XXXX') {
      if (trafficWindow.type === 'LOOP_WITH_PLATFORM') {
        this.setInteractive(new Phaser.Geom.Rectangle(45, positionYOfCdv - 10, 55, 45), Phaser.Geom.Rectangle.Contains)
        this.on('pointerover', () => scene.input.setDefaultCursor('pointer'))
        this.on('pointerout', () => scene.input.setDefaultCursor('default'))
      } else {
        this.setInteractive(
          new Phaser.Geom.Rectangle(
            -5,
            newLineMappingConstructionMethod ? positionYOfPlatformDisplay - 30 : this.getInteractivityRange({
              positionYOfText,
              positionYOfCdv,
              showStationName,
              trackNumber: trafficWindow.track,
            }),
            55,
            trafficWindow.track === 2 ? 65 : 50,
          ),
          Phaser.Geom.Rectangle.Contains,
        )
        this.on('pointerover', () => scene.input.setDefaultCursor('pointer'))
        this.on('pointerout', () => scene.input.setDefaultCursor('default'))
      }
    }
  }

  private getInteractivityRange({ positionYOfText, positionYOfCdv, showStationName, trackNumber }: {
    positionYOfText: number;
    positionYOfCdv: number;
    showStationName: boolean;
    trackNumber: number;
  }) {
    const positionInCaseOfTrackTwo = showStationName ? positionYOfText - 80 : positionYOfText - 50
    return trackNumber === 2 ? positionInCaseOfTrackTwo : positionYOfCdv + 55
  }

  get platformLinkedCdv(): string[] {
    return this._platformLinkedCdv
  }

  get trackElement(): TrackElement {
    return this._trackElement
  }

  get platformState(): PlatformStateType {
    return this._platformState
  }

  set platformState(state: PlatformStateType) {
    this._platformState = state
  }

  set isReferentialStation(value: boolean) {
    this._isReferentialStation = value
  }

  get id(): string {
    return this._id
  }

  get platformTimeId(): string {
    return this._platformTimeId
  }

  get platformDouble(): string {
    return this._platformDouble
  }

  get platformName(): string {
    return this._platformName
  }

  get connections(): Connection[] {
    return this._connections
  }

  get management(): string {
    return this._management
  }

  get ciClub(): boolean {
    return this._ciClub
  }

  get positionYOfCdv(): number {
    return this._positionYOfCdv
  }

  get track(): number {
    return this._track
  }

  get referentialStation(): boolean {
    return this._isReferentialStation
  }

  get liveEventElement(): LiveEventElement {
    return this._liveEventElement
  }

  buildChildren(trafficWindow: TrafficWindow, scene: Phaser.Scene, positionYOfCdv: number, line: string, positionYOfText: number, positionYOfPlatformDisplay: number, showStationText: boolean, userPreferences: UserPreferences, newLineMappingConstructionMethod: boolean) {
    const children: GameObject[] = new Array<GameObject>()
    this._trackElement = new TrackElement(scene, trafficWindow, positionYOfCdv, line)
    children.push(this._trackElement)

    this._liveEventElement = LiveEventElement.buildLiveElement({
      scene,
      trafficWindow,
      positionYOfPlatformDisplay,
      userPreferences,
      line,
      showStationText,
      newLineMappingConstructionMethod,
    })
    if (this._liveEventElement) {
      children.push(this._liveEventElement)
    }

    if (trafficWindow.platFormName) {
      children.push(new PlatformDisplayContainer(scene, positionYOfPlatformDisplay, trafficWindow))
      if (showStationText) {
        if (trafficWindow.connections) {
          children.push(TrackElementCorrespondance(scene, positionYOfText, trafficWindow, newLineMappingConstructionMethod))
        }
        children.push(new TrackElementText(scene, positionYOfText, trafficWindow, newLineMappingConstructionMethod))
      }
    }
    return children
  }
}

/**
 * groupage des elements qui forment une voie
 */
export class GroupTrackElement extends AbstractGroupElement {
  private readonly defaultMarginPercent = 0.05

  getWidth(): { min: number; max: number } {
    const arr = this.children.entries.map((container) => (container as ContainerTrackElement).x)
    let max = Math.max(...arr)
    max += max * this.defaultMarginPercent
    let min = Math.min(...arr)
    min -= max * this.defaultMarginPercent
    return { min, max }
  }
  getAllContainerTrackElement(): ContainerTrackElement[] {
    return super.getChildren().map((child) => child as ContainerTrackElement)
  }

  getHeight(): { min: number; max: number } {
    const arr = this.children.entries.map((container) => (container as ContainerTrackElement).positionYOfCdv)
    let max = Math.max(...arr)
    max += max * this.defaultMarginPercent
    let min = Math.min(...arr)
    min -= max * this.defaultMarginPercent
    return { min, max }
  }

  getPlatformDisplayWindowsInformationContainerByTrafficWindow(windowNumber: string): PlatformDisplayContainer {
    const container = super.getChildren().filter((child) => (child as ContainerTrackElement).platformTimeId === windowNumber.toString())[0] as ContainerTrackElement
    return container ? (container.getAll().filter((child) => child instanceof PlatformDisplayContainer)[0] as PlatformDisplayContainer) : null
  }

  getContainerByTrafficWindow(trafficWindowId: string): ContainerTrackElement {
    return super.getChildren().filter((child) => (child as ContainerTrackElement).id === trafficWindowId.toString())[0] as ContainerTrackElement
  }

  getAllContainerByTrafficWindow(trafficWindowId: string): Phaser.GameObjects.GameObject[] {
    return super.getChildren().filter((child) => (child as ContainerTrackElement).id === trafficWindowId.toString())
  }

  getContainerByPlatformTimeId(trafficTimeWindowId: string): ContainerTrackElement {
    return (
      super
        .getChildren()
        .filter((child) => (child as ContainerTrackElement).platformTimeId === trafficTimeWindowId.toString())[0] as ContainerTrackElement
    )
  }

  getLiveEvents(): LiveEventElement[] {
    return super
      .getChildren()
      .reduce((liveEvents: LiveEventElement[], child: ContainerTrackElement) => liveEvents.concat(child.liveEventElement), [])
      .filter((liveEvent) => liveEvent)
  }
}
