// Design Pattern Factory pour créer les différents éléments de plateforme
import Graphics = Phaser.GameObjects.Graphics
import Image = Phaser.GameObjects.Image
import {
  PlatformClosedDisplayElement,
  PlatformDefaultDisplayElement,
  PlatformElapsedDisplayElement,
  PlatformTargetDisplayElement,
  PlatformTrack1ReferentialStationDisplayElement,
  PlatformTrack2ReferentialStationDisplayElement,
} from './plateform-display-elements'
import { Platform } from '../../../../app/core/models/platform-info-traffic'
import { TimeDisplayElementOption } from './models/time-display-element-option.model'
import { PlatformElementType } from './models/platform-element.type.model'

/**
 * Options to create platform elements.
 * @interface CreatePlatformOptions
 * @property {TimeDisplayElementOption} [timeDisplayElementOption] - Configuration options for time display elements.
 * @property {Platform} [platform] - The platform object required for types of elements (exp: elapsed).
 */
export interface CreatePlatformOptions {
  timeDisplayElementOption?: TimeDisplayElementOption
  platform?: Platform
}

/**
 * Factory Design Pattern: PlatformElementFactory
 *
 * This class implements the Factory Pattern to create different types of platform elements.
 * It centralizes the creation logic for platform elements.
 */
export class PlatformElementFactory {
  private scene: Phaser.Scene

  constructor(scene: Phaser.Scene) {
    this.scene = scene
  }

  createPlatformElement(type: PlatformElementType, options?: CreatePlatformOptions): Graphics | Image {
    switch (type) {
      case 'default':
        if (options?.timeDisplayElementOption) {
          return new PlatformDefaultDisplayElement(this.scene, options.timeDisplayElementOption)
        }
        throw new Error('Missing timeDisplayElementOption for default platform element')
      case 'target':
        return new PlatformTargetDisplayElement(this.scene)
      case 'elapsed':
        if (options?.platform) {
          return new PlatformElapsedDisplayElement(this.scene, options.platform)
        }
        throw new Error('Missing platform option for elapsed platform element')
      case 'closed':
        return new PlatformClosedDisplayElement(this.scene)
      case 'track1-referential-station':
        if (options?.timeDisplayElementOption) {
          return new PlatformTrack1ReferentialStationDisplayElement(this.scene, options.timeDisplayElementOption)
        }
        throw new Error('Missing timeDisplayElementOption for platform track 1 referencial station display element')
      case 'track2-referential-station':
        if (options?.timeDisplayElementOption) {
          return new PlatformTrack2ReferentialStationDisplayElement(this.scene, options.timeDisplayElementOption)
        }
        throw new Error('Missing timeDisplayElementOption for platform track 2 referencial station display element')
      default:
        throw new Error(`Unknown platform element type: ${type}`)
    }
  }
}