import { Component, Input } from '@angular/core'
import { Interval, IntervalLine } from '../../core/models/interval'
import { SelectedChoice } from '../interval-mobile/interval-mobile.component'
import { PlatformService } from '../../core/services/platform.service'

@Component({
  selector: 'app-interval-mobile-detail',
  templateUrl: './interval-mobile-detail.component.html',
  styleUrls: ['./interval-mobile-detail.component.scss'],
})
export class IntervalMobileDetailComponent {
  @Input() selectedChoice: SelectedChoice
  @Input() interval: Interval

  constructor(public platformService: PlatformService) {
  }

  trackByIntervalLineName(index: number, item: IntervalLine) {
    return item.lineName
  }
}
