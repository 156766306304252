import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'

export const SIZE = {
  LG: 992,
  FULLSCREEN_WIDTH: 1920
}

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(private platform: Platform) {}

  isOnlyDesktopPlatform() {
    return this.platform.is('desktop') || this.platform.is('electron')
  }

  isPlatformDesktop() {
    return this.platform.is('desktop') || ((this.platform.is('mobile') || this.platform.is('mobileweb') || this.platform.is('tablet')) && !(this.platform.is('ios') || this.platform.is('android') || this.isPlatformNative() || this.platform.is('electron')))
  }

  isPlatformTablet() {
    return this.platform.is('tablet') && (this.platform.is('ios') || this.platform.is('android') || this.isPlatformNative())
  }

  isPlatformMobile() {
    return this.platform.is('mobile') && (this.platform.is('ios') || this.platform.is('android') || this.isPlatformNative()) && !this.platform.is('tablet')
  }

  isPlatformIos() {
    return this.platform.is('ios')
  }

  isPlatformNative() {
    return this.platform.is('hybrid')
  }

  isPlatformNot(type: any) {
    return !this.platform.is(type)
  }

  isPlatform(type: any) {
    return this.platform.is(type)
  }

  getPlatformWidth(): any {
    return this.platform.width()
  }

  isLgSize() {
    return this.platform.width() < SIZE.LG
  }

  isFullScreenCompatible()  {
    return this.platform.width() >= SIZE.FULLSCREEN_WIDTH
  }

  isMobileWindows() {
    return this.isPlatform('mobileweb') && this.isPlatformNot('ios') && this.isPlatformNot('android')
  }
}
