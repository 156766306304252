import 'phaser'
import { ImageSvgManager } from '../managers/image.manager'
import { ContainerTrackElement, GroupTrackElement } from '../game-objects/groups/track/track.element'
import { SceneController } from '../controllers/scene.controller'
import { SceneType } from '../managers/scene.manager'
import { ContainerTrainElement, GroupTrainsElement } from '../game-objects/groups/train/train.element'
import { MainGame } from '../main.game'
import { TextBitmapManager } from '../managers/text.manager'
import { controlDeviceConfig } from '../configs/configuration.navigation.webApp'
import PathFollowerPlugin from 'phaser3-rex-plugins/plugins/pathfollower-plugin'

/**
 * impératif rester à du 60 images/s
 */
export class MainScene extends Phaser.Scene {
  public sceneController: SceneController
  private cursors: Phaser.Types.Input.Keyboard.CursorKeys
  private readonly _groupTrackElementLineMapping: GroupTrackElement
  private imageManager: ImageSvgManager
  private textBitmapManager: TextBitmapManager
  private readonly _groupTrainsElement: GroupTrainsElement

  constructor() {
    super({ key: SceneType.MAIN })
    this.sceneController = new SceneController(this)
    this._groupTrackElementLineMapping = new GroupTrackElement(this, null, {
      name: 'lines',
    })
    this._groupTrainsElement = new GroupTrainsElement(this, null, {
      name: 'trains',
    })
  }

  get groupTrainsElement(): GroupTrainsElement {
    return this._groupTrainsElement
  }

  get groupTrackElementLineMapping(): GroupTrackElement {
    return this._groupTrackElementLineMapping
  }

  /**
   * on peut charger la donnée au start restart de la scene
   */
  init() {
    ;(this.game as MainGame).gameStateService.initializationGameState.next('UNREADY')
  }

  /**
   * chargement des images lignes track
   */
  preload() {
    this.imageManager = new ImageSvgManager(this)
    this.textBitmapManager = new TextBitmapManager(this)
    try {
      this.load.plugin('rexPathFollower', PathFollowerPlugin, true)
    } catch (error) {
      console.error('Failed to load plugin:', error)
    }
  }

  create() {
    controlDeviceConfig(this.cameras.main, this)
    this.cursors = this.input.keyboard.createCursorKeys()
    this.input.on('gameobjectdown', this.eventTriggerOnPhaserObjectTouch)
    ;(this.game as MainGame).gameStateService.initializationGameState.next('READY')
  }

  update(time, delta) {
  }

  private eventTriggerOnPhaserObjectTouch(pointer, gameObject: ContainerTrainElement | ContainerTrackElement) {
    if (gameObject instanceof ContainerTrackElement) {
      ;((this.scene as any).game as MainGame).touchedTrackObjectSubject.next(gameObject)
    } else {
      ;((this.scene as any).game as MainGame).touchedTrainObjectSubject.next(gameObject)
    }
  }
}
