import {Component, DestroyRef, OnInit} from '@angular/core'
import { MenuController } from '@ionic/angular'
import { SceneType } from '../../../phaser/managers/scene.manager'
import { UrlService } from '../../core/services/url.service'
import { UserService } from '../../core/security/user.service'
import { PlatformService } from '../../core/services/platform.service'
import {inject} from "@angular/core";

@Component({
  selector: 'app-menu-side',
  templateUrl: './menu-side.component.html',
  styleUrls: ['./menu-side.component.scss'],
})
export class MenuSideComponent{
  private menu = inject(MenuController);
  public platformService = inject(PlatformService);
  public urlService = inject(UrlService);
  public userService = inject(UserService);

  close() {
    this.menu.close(SceneType.MAIN).then()
  }
}
