import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { AuthenticationInformation, SecurityPrivileges } from '../models/authentication-information'
import { filter, map, shareReplay } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  get currentUserState$(): Observable<AuthenticationInformation> {
    return this._currentUserState$
  }

  get currentUserHasTcoInformationPrivilege$(): Observable<boolean> {
    return this._currentUserHasTcoInformationPrivilege$
  }

  get currentIsNotDenied$(): Observable<boolean> {
    return this.currentUserState$.pipe(
      filter((user) => !!user),
      map((user) => (user.roles ? !user.roles.includes(SecurityPrivileges.ACCESS_ROLE_DENIED) : false))
    )
  }

  get isAUserAlreadyConnected$(): Observable<boolean> {
    return this._currentUserState$.pipe(
      filter((user) => !!user),
      map((user) => (!(user === null || user === undefined)))
    )
  }

  get currentUserHasPeakPeriodInformationPrivilege$(): Observable<boolean> {
    return this._currentUserHasPeakPeriodInformationPrivilege$
  }

  get currentUserHasTrainsLateInformationPrivilege$(): Observable<boolean> {
    return this._currentUserHasTrainsLateInformationPrivilege$
  }

  get currentUserHasTrainTrackingInformationPrivilege$(): Observable<boolean> {
    return this._currentUserHasTrainTrackingInformationPrivilege$
  }

  get currentUserHasTrainTrackingWithoutTrainInformationPrivilege$(): Observable<boolean> {
    return this._currentUserHasTrainTrackingWithoutTrainInformationPrivilege$
  }

  get currentUserHasTrainInformationPrivilege$(): Observable<boolean> {
    return this._currentUserHasTrainInformationPrivilege$
  }

  get currentUserHasTrainInformationForAttachmentLinePrivilege$(): Observable<boolean> {
    return this._currentUserHasTrainInformationForAttachmentLinePrivilege$
  }

  get currentUserHasTrainInformationForOverParkingPrivilege$(): Observable<boolean> {
    return this._currentUserHasTrainInformationForOverParkingPrivilege$
  }

  get currentUserSubject(): BehaviorSubject<AuthenticationInformation> {
    return this._currentUserSubject
  }

  private readonly _currentUserSubject: BehaviorSubject<AuthenticationInformation> = new BehaviorSubject<AuthenticationInformation>(null)
  private readonly _currentUserState$: Observable<AuthenticationInformation> = this._currentUserSubject.asObservable().pipe(shareReplay(1))
  private readonly _currentUserHasTrainTrackingInformationPrivilege$: Observable<boolean> = new Observable<boolean>()
  private readonly _currentUserHasTrainsLateInformationPrivilege$: Observable<boolean> = new Observable<boolean>()
  private readonly _currentUserHasPeakPeriodInformationPrivilege$: Observable<boolean> = new Observable<boolean>()
  private readonly _currentUserHasTcoInformationPrivilege$: Observable<boolean> = new Observable<boolean>()
  private readonly _currentUserHasTrainTrackingWithoutTrainInformationPrivilege$: Observable<boolean> = new Observable<boolean>()
  private readonly _currentUserHasTrainInformationPrivilege$: Observable<boolean> = new Observable<boolean>()
  private readonly _currentUserHasTrainInformationForAttachmentLinePrivilege$: Observable<boolean> = new Observable<boolean>()
  private readonly _currentUserHasTrainInformationForOverParkingPrivilege$: Observable<boolean> = new Observable<boolean>()

  constructor() {
    this._currentUserHasTrainTrackingInformationPrivilege$ = this._currentUserSubject.pipe(
      shareReplay(1),
      filter((auth) => !!auth),
      map((auth) => (auth.roles ? auth.roles.includes(SecurityPrivileges.TRAIN_TRACKING_COMPLETE) : false))
    )

    this._currentUserHasTrainsLateInformationPrivilege$ = this._currentUserSubject.pipe(
      shareReplay(1),
      filter((auth) => !!auth),
      map((auth) => (auth.roles ? auth.roles.includes(SecurityPrivileges.LATE_ACTION_COMPLETE) : false))
    )

    this._currentUserHasPeakPeriodInformationPrivilege$ = this._currentUserSubject.pipe(
      shareReplay(1),
      filter((auth) => !!auth),
      map((auth) => (auth.roles ? auth.roles.includes(SecurityPrivileges.PEAK_PERIOD_COMPLETE) : false))
    )

    this._currentUserHasTcoInformationPrivilege$ = this._currentUserSubject.pipe(
      shareReplay(1),
      filter((auth) => !!auth),
      map((auth) => (auth.roles ? auth.roles.includes(SecurityPrivileges.TCO_COMPLETE) : false))
    )

    this._currentUserHasTrainTrackingWithoutTrainInformationPrivilege$ = this._currentUserSubject.pipe(
      shareReplay(1),
      filter((auth) => !!auth),
      map((auth) => (auth.roles ? auth.roles.includes(SecurityPrivileges.TRAIN_TRACKING_WITHOUT_TRAIN_INFORMATION) : false))
    )

    this._currentUserHasTrainInformationPrivilege$ = this._currentUserSubject.pipe(
      shareReplay(1),
      filter((auth) => !!auth),
      map((auth) => (auth.roles ? auth.roles.includes(SecurityPrivileges.TRAIN_INFO_COMPLETE) : false))
    )

    this._currentUserHasTrainInformationForAttachmentLinePrivilege$ = this._currentUserSubject.pipe(
      shareReplay(1),
      filter((auth) => !!auth),
      map((auth) => (auth.roles ? auth.roles.includes(SecurityPrivileges.TRAIN_INFO_FOR_ATTACHMENT_LINE) : false))
    )

    this._currentUserHasTrainInformationForOverParkingPrivilege$ = this._currentUserSubject.pipe(
      shareReplay(1),
      filter((auth) => !!auth),
      map((auth) => (auth.roles ? auth.roles.includes(SecurityPrivileges.OVER_PARKING_COMPLETE) : false))
    )
  }
}
