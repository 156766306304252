<!--<ion-row [class.header-delimiter]="(routerTitle$ | async | translate) === 'Intervalles'" [class.ion-justify-content-center]="platformService.isPlatformDesktop()">-->
<!--  <ion-col class="no-padding" [class.ion-align-self-start]="platformService.isPlatformDesktop()" [class.wrapper-header]="platformService.isPlatformDesktop()">-->
<!--    <ion-toolbar>-->
<!--      <div *ngIf="routerTitle$ | async as title" class="path-title" slot="start">-->
<!--        {{ title | translate }}-->
<!--      </div>-->
<!--    </ion-toolbar>-->
<!--  </ion-col>-->
<!--</ion-row>-->

<div *ngIf="routerTitle$ | async as title" class="path-title" slot="start">
    {{ title | translate }}
</div>
