import { NgModule } from '@angular/core'
import { CommonModule, DatePipe } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { HttpClient } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { IonicModule } from '@ionic/angular'
import { RouterModule } from '@angular/router'
import { PeakPeriodMobileComponent } from './peak-period-mobile/peak-period-mobile.component'
import { PeakPeriodCartridgeComponent } from './peak-period-cartridge/peak-period-cartridge.component'
import { PeakPeriodDetailComponent } from './peak-period-detail/peak-period-detail.component'
import { PeakPeriodGlobalComponent } from './peak-period-global/peak-period-global.component'
import { LinesSliderComponent } from './lines-slider/lines-slider.component'
import { IntervalMobileComponent } from './interval-mobile/interval-mobile.component'
import { IntervalGlobalComponent } from './interval-global/interval-global.component'
import { IntervalCartridgeComponent } from './interval-cartridge/interval-cartridge.component'
import { IntervalDetailComponent } from './interval-detail/interval-detail.component'
import { IntervalMobileDetailComponent } from './interval-mobile-detail/interval-mobile-detail.component'
import { PipesModule } from '../core/pipe/pipes.module'
import { PeakPeriodScheduleComponent } from './peak-period-schedule/peak-period-schedule.component'
import { TimeSwitchComponent } from './time-switch/time-switch.component'
import {
  LiveTrafficSwitcherButtonComponent,
} from './live-traffic-switcher-button/live-traffic-switcher-button.component'
import { FullScreenButtonComponent } from './full-screen-button/full-screen-button.component'
import { StationInformationComponent } from './station-information/station-information.component'
import { DelaySwitchComponent } from './delay-switch/delay-switch.component'
import { ProductionSwitcherButtonComponent } from './production-switcher-button/production-switcher-button.component'
import { TitleHeaderComponent } from './title-header/title-header.component'
import { WindowListenerDirective } from './directives/window-listener.directive'
import { SvgDisplayComponent } from './svg-display/svg-display.component'
import { TrainInformationComponent } from './train-information/train-information.component'
import { InformationTagComponent } from './information-tag/information-tag.component'
import { DisruptionComponent } from './disruption/disruption.component'
import { LineSelectorComponent } from './line-selector/line-selector.component'
import { LoadingComponent } from './loading/loading.component'
import { SpinnerComponent } from './spinner/spinner.component'
import { TkOffPeakHourScheduleComponent } from './tk-off-peak-hour-schedule/tk-off-peak-hour-schedule.component'
import { PeakPeriodDisplayComponent } from './peak-period-display/peak-period-display.component'
import { TkOffPeakHourDisplayComponent } from './tk-off-peak-hour-display/tk-off-peak-hour-display.component'
import { TkDetailComponent } from './tk-detail/tk-detail.component'
import { TkStatisticsComponent } from './tk-statistics/tk-statistics.component'
import { TkStatisticsCartridgeComponent } from './tk-statistics-cartridge/tk-statistics-cartridge.component'
import { TkSwitcherButtonComponent } from './tk-switcher-button/tk-switcher-button.component'
import {
  TkOffPeakHourGlobalDisplayComponent,
} from './tk-off-peak-hour-display-global/tk-off-peak-hour-global-display.component'
import { RoundPipe } from './pipe/round.pipe'
import { AffluenceSwitchComponent } from './affluence-switch/affluence-switch.component'
import { LogoutButtonComponent } from './logout-button/logout-button.component'
import { InfoVoyagerComponent } from './info-voyager/info-voyager.component'
import { IncidentSwitchComponent } from './incident-switch/incident-switch.component'
import {InfoLineComponent} from "./info-line/info-line.component";
import {
  TranslateXDirective
} from '../pages/real-time-traffic-fullscreen/real-time-traffic-wrapper/directives/translate-x.directive'

@NgModule({
  declarations: [
    // shared components
    IntervalMobileComponent,
    IntervalGlobalComponent,
    IntervalCartridgeComponent,
    PeakPeriodMobileComponent,
    PeakPeriodCartridgeComponent,
    PeakPeriodDetailComponent,
    PeakPeriodGlobalComponent,
    LinesSliderComponent,
    IntervalMobileDetailComponent,
    IntervalDetailComponent,
    PeakPeriodScheduleComponent,
    TimeSwitchComponent,
    LiveTrafficSwitcherButtonComponent,
    FullScreenButtonComponent,
    LogoutButtonComponent,
    StationInformationComponent,
    TrainInformationComponent,
    DelaySwitchComponent,
    AffluenceSwitchComponent,
    ProductionSwitcherButtonComponent,
    TitleHeaderComponent,
    WindowListenerDirective,
    InfoLineComponent,
    SvgDisplayComponent,
    InformationTagComponent,
    DisruptionComponent,
    LineSelectorComponent,
    LoadingComponent,
    SpinnerComponent,
    TkOffPeakHourScheduleComponent,
    PeakPeriodDisplayComponent,
    TkOffPeakHourDisplayComponent,
    TkDetailComponent,
    TkStatisticsComponent,
    TkStatisticsCartridgeComponent,
    TkSwitcherButtonComponent,
    TkOffPeakHourGlobalDisplayComponent,
    InfoVoyagerComponent,
    RoundPipe,
    IncidentSwitchComponent,
    TranslateXDirective
  ],
  imports: [
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'fr',
    }),
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    PipesModule,
    ReactiveFormsModule,
  ],
  exports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    IonicModule,
    // pipes
    PipesModule,
    // shared components
    PeakPeriodMobileComponent,
    PeakPeriodCartridgeComponent,
    PeakPeriodDetailComponent,
    PeakPeriodGlobalComponent,
    LinesSliderComponent,
    IntervalMobileComponent,
    PeakPeriodScheduleComponent,
    TimeSwitchComponent,
    LogoutButtonComponent,
    LiveTrafficSwitcherButtonComponent,
    FullScreenButtonComponent,
    StationInformationComponent,
    TrainInformationComponent,
    DelaySwitchComponent,
    AffluenceSwitchComponent,
    ProductionSwitcherButtonComponent,
    TitleHeaderComponent,
    WindowListenerDirective,
    InfoLineComponent,
    SvgDisplayComponent,
    DisruptionComponent,
    LineSelectorComponent,
    LoadingComponent,
    TkOffPeakHourScheduleComponent,
    PeakPeriodDisplayComponent,
    TkOffPeakHourDisplayComponent,
    TkOffPeakHourGlobalDisplayComponent,
    InfoVoyagerComponent,
    IncidentSwitchComponent,
    TranslateXDirective,
    RoundPipe
  ],
  providers: [DatePipe],
})
export class SharedModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}
