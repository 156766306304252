import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { UserService } from './user.service'
import { URL_CONFIG } from '../configurations/urls'

@Injectable({
  providedIn: 'root',
})
export class CanActivateTMVisitorGrantedAccessGuard  {
  constructor(private userService: UserService, private router: Router) {}

  /**
   * vérification des roles du token,
   * la validité est faite par le chargement de la donnée ou de 'initialisation et donc toute les secondes au moins
   * route
   * state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.isAuthenticated()
  }

  isAuthenticated() {
    return this.userService.currentUserState$.pipe(
      take(1),
      map((user) => !!user && !!user.roles),
      map((res) => {
        return !res ? this.router.parseUrl(URL_CONFIG.URL_RESTRICTED_ACCESS) : res
      })
    )
  }
}
