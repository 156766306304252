<ion-grid [class.no-padding]="!platformService.isPlatformDesktop() || !platformService.isPlatformTablet()">
  <ion-row [class.no-padding]="!platformService.isPlatformDesktop() || !platformService.isPlatformTablet()">
    <ion-col *ngIf="selectedChoiceObservable | async as selectedChoice" [class.no-padding]="!platformService.isPlatformDesktop() || !platformService.isPlatformTablet()">
      <ion-row>
        <app-interval-global [intervalStatistics]="intervalStatistics | async"></app-interval-global>
      </ion-row>
      <ion-row>
        <ion-col>
          <h4>
            {{ 'app.shared.dashboard.production.interval.line.detail.title' | translate }}
          </h4>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-buttons [class.mobile-button]="!platformService.isPlatformDesktop() && !platformService.isPlatformTablet()">
          <ion-button (click)="toggle('SUM')" [class.select]="selectedChoice === 'SUM'">{{ 'app.shared.dashboard.production.interval.mobile.sum' | translate }}</ion-button>
          <ion-button (click)="toggle('TRACK_ONE')" [class.select]="selectedChoice === 'TRACK_ONE'">{{ 'app.shared.dashboard.production.interval.mobile.firstTrack' | translate }}</ion-button>
          <ion-button (click)="toggle('TRACK_TWO')" [class.select]="selectedChoice === 'TRACK_TWO'">{{ 'app.shared.dashboard.production.interval.mobile.secondTrack' | translate }}</ion-button>
        </ion-buttons>
      </ion-row>
      <ion-row [class.no-padding]="!platformService.isPlatformDesktop() || !platformService.isPlatformTablet()">
        <app-interval-mobile-detail [interval]="interval | async" [selectedChoice]="selectedChoice"></app-interval-mobile-detail>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
