import Phaser from 'phaser'
import { TrafficWindow } from '../../../modeles/lines'
import { ImageSvgManager, SvgImages } from '../../../managers/image.manager'
import { Agent, AgentDto, Incident, IncidentDto, LiveEvent } from '../../../../app/core/models/live-event'
import { UserPreferences } from '../../../../app/core/configurations/user/user.preferences'

enum LiveEventType {
  AGENT = 'AGENT',
  INCIDENT = 'INCIDENT',
  BOTH = 'BOTH',
  NONE = '',
}

export class LiveEventElement extends Phaser.GameObjects.Image {
  private associatedPlatformId: string
  private userPreferences: UserPreferences
  private linkedLiveEvent: LiveEvent | undefined
  private line: string
  private liveEventType: LiveEventType

  constructor({
                scene,
                trafficWindow,
                positionYOfPlatformDisplay,
                userPreferences,
                line,
                showStationText,
                newLineMappingConstructionMethod,
              }: {
    scene: Phaser.Scene;
    trafficWindow: TrafficWindow;
    positionYOfPlatformDisplay: number;
    userPreferences: UserPreferences;
    line: string;
    showStationText: boolean,
    newLineMappingConstructionMethod: boolean
  }) {
    super(scene, 0, 0, '')
    this.setImagePosition({
      positionYOfPlatformDisplay,
      trackNumber: trafficWindow.track,
      showStationText,
      newLineMappingConstructionMethod,
    })
    this.initImageState({ trafficWindow, userPreferences, line })
  }

  public static buildLiveElement({
                                   scene,
                                   trafficWindow,
                                   positionYOfPlatformDisplay,
                                   userPreferences,
                                   line,
                                   showStationText,
                                   newLineMappingConstructionMethod,
                                 }: {
    scene: Phaser.Scene
    trafficWindow: TrafficWindow
    positionYOfPlatformDisplay: number
    userPreferences: UserPreferences
    line: string
    showStationText: boolean,
    newLineMappingConstructionMethod: boolean
  }): LiveEventElement | null {
    return trafficWindow?.platFormName // we build only element for platform
      ? new LiveEventElement({
        scene,
        trafficWindow,
        positionYOfPlatformDisplay,
        userPreferences,
        line,
        showStationText,
        newLineMappingConstructionMethod,
      })
      : null
  }

  private setImagePosition({
                             positionYOfPlatformDisplay,
                             trackNumber,
                             showStationText,
                             newLineMappingConstructionMethod,
                           }: {
    positionYOfPlatformDisplay: number;
    trackNumber: number;
    showStationText: boolean;
    newLineMappingConstructionMethod: boolean;
  }): void {
    const X_OFFSET = 22
    const Y_OFFSET = newLineMappingConstructionMethod ? 0 : this.getVerticalFOffset({ trackNumber, showStationText })
    this.setY(positionYOfPlatformDisplay + Y_OFFSET)
    this.setX(X_OFFSET)
  }

  private getVerticalFOffset({ trackNumber, showStationText }: {
    trackNumber: number;
    showStationText: boolean
  }): number {
    const trackOneOffset = 72
    const trackTwoOffset = showStationText ? -52 : -20
    return trackNumber === 2 ? trackTwoOffset : trackOneOffset
  }

  private initImageState({ trafficWindow, userPreferences, line }: {
    trafficWindow: TrafficWindow;
    userPreferences: UserPreferences;
    line: string
  }): void {
    this.associatedPlatformId = trafficWindow.id
    this.userPreferences = userPreferences
    this.line = line
    this.setVisibility(false)
  }

  public updateStateFrom({ liveEventElement, incidents, agents }: {
    liveEventElement: LiveEventElement;
    incidents: IncidentDto[];
    agents: AgentDto[]
  }): void {
    const platformId = liveEventElement.getPlatformId()
    const agentsLinkedForPlatformId = agents?.filter((agent) => agent.platformId === platformId) ?? []
    const incidentsLinkedForPlatformId = incidents?.filter((agent) => agent.platformId === platformId) ?? []

    if (agentsLinkedForPlatformId.length > 0 || incidentsLinkedForPlatformId.length > 0) {
      this.linkedLiveEvent = LiveEvent.From({
        platformId,
        agents: agentsLinkedForPlatformId,
        incidents: incidentsLinkedForPlatformId,
      })
      this.setLiveEventType()
      this.setImageSrc()
      this.setVisibility(this.isLiveElementVisible())
    } else {
      this.setVisibility(false)
    }
  }

  private setLiveEventType(): void {
    if (this.linkedLiveEvent?.hasAgents() && !this.linkedLiveEvent?.hasIncidents()) {
      this.liveEventType = LiveEventType.AGENT
    } else if (!this.linkedLiveEvent?.hasAgents() && this.linkedLiveEvent?.hasIncidents()) {
      this.liveEventType = LiveEventType.INCIDENT
    } else if (this.linkedLiveEvent?.hasAgents() && this.linkedLiveEvent?.hasIncidents()) {
      this.liveEventType = LiveEventType.BOTH
    } else {
      this.liveEventType = LiveEventType.NONE
    }
  }

  private setImageSrc(): void {
    const imageReferential: Record<string, SvgImages> = {
      AGENT: SvgImages.LIVE_EVENT_AGENT,
      INCIDENT: SvgImages.LIVE_EVENT_INCIDENT,
      BOTH: SvgImages.LIVE_EVENT_BOTH,
    }
    const icon: string | undefined = ImageSvgManager.getNameOfImage(imageReferential[this.liveEventType])
    if (icon) {
      this.setTexture(icon)
    }
  }

  private setVisibility(isVisible: boolean): void {
    this.setVisible(isVisible)
  }

  public isLiveEventDetailVisible(): boolean {
    return this.isLiveElementVisible() && this.visible
  }

  public isLiveElementVisible(): boolean {
    return !!this.linkedLiveEvent && this.userPreferences.selectedIncidents
  }

  public displayLineName(): string {
    return this.line
  }

  public displayAgents(): Agent[] {
    return this.linkedLiveEvent?.displayAgents()
  }

  public displayIncidents(): Incident[] {
    return this.linkedLiveEvent?.displayIncidents()
  }

  public displayNumberOfAgents(): number {
    return this.linkedLiveEvent?.getNumberOfAgents() ?? 0
  }

  public displayNumberOfIncidents(): number {
    return this.linkedLiveEvent?.getNumberOfIncidents() ?? 0
  }

  public isAgentLiveEvent(): boolean {
    return this.liveEventType === LiveEventType.AGENT
  }

  public isIncidentLiveEvent(): boolean {
    return this.liveEventType === LiveEventType.INCIDENT
  }

  public isAgentAndIncidentLiveEvent(): boolean {
    return this.liveEventType === LiveEventType.BOTH
  }

  public getPlatformId() {
    return this.associatedPlatformId
  }
}
