import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

// A small helper that merges keys from unsafeAppEnv into environment
function patchEnvironmentFromWindow() {
  const unsafeEnv = (window as any).unsafeAppEnv || {};
  Object.keys(unsafeEnv).forEach((key) => {
    (environment as any)[key] = unsafeEnv[key];
  });
}

patchEnvironmentFromWindow();

// If environment.production is true, enable Angular production mode
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err))
