import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { paths } from '../../../../environments/paths'
import {
  DeviceNotificationConfiguration,
  NotificationConfiguration,
  UserDesktopNotification,
} from './modele/notifications'

@Injectable({
  providedIn: 'root',
})
export class ApiNotificationService {
  constructor(private httpClient: HttpClient) {
  }

  getDeviceNotificationInfo(deviceId: string): Observable<DeviceNotificationConfiguration> {
    return this.httpClient.get<DeviceNotificationConfiguration>(paths.devices + '/' + deviceId + '/notification')
  }

  updateDeviceNotification(deviceNotificationConfiguration: DeviceNotificationConfiguration): Observable<DeviceNotificationConfiguration> {
    return this.httpClient.post<DeviceNotificationConfiguration>(paths.updateDeviceNotification, deviceNotificationConfiguration)
  }

  // TODO how to handle VOID type ? from MONO<Void>
  removeDeviceFromCurrentUser(deviceId: string): Observable<any> {
    return this.httpClient.delete<any>(paths.devices + '/' + deviceId)
  }

  updateNotificationConfigurationForCurrentUser(notificationConfiguration: NotificationConfiguration): Observable<NotificationConfiguration> {
    return this.httpClient.post<NotificationConfiguration>(paths.notifications, notificationConfiguration)
  }

  getNotificationConfigurationForCurrentUser(): Observable<NotificationConfiguration> {
    return this.httpClient.get<NotificationConfiguration>(paths.notifications)
  }

  // Result will be handled in service worker to display desktop notification
  getDesktopNotification(): Observable<UserDesktopNotification> {
    return this.httpClient.get<UserDesktopNotification>(paths.desktopNotification)
  }
}
