import { Injectable } from '@angular/core'
import { Event, NavigationEnd, Router } from '@angular/router'
import { filter, take, tap } from 'rxjs/operators'
import { UserService } from '../security/user.service'
import { UrlService } from './url.service'
import { BehaviorSubject, forkJoin } from 'rxjs'
import { Device, DeviceId, DeviceInfo } from '@capacitor/device'
import { App, AppInfo } from '@capacitor/app'
import { PlatformService } from './platform.service'
import { AuthenticationInformation } from '../models/authentication-information'
import { AngularFireAnalytics } from '@angular/fire/compat/analytics'

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private pageName: BehaviorSubject<string> = new BehaviorSubject<string>(null)

  constructor(private router: Router, private angularFireAnalytics: AngularFireAnalytics, private userService: UserService, private urlService: UrlService, public platformService: PlatformService) {
    router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        tap(this.handleRootHashChange),
      )
      .subscribe()
    this.extractedUserInfoAndDeviceInfo(userService, angularFireAnalytics)
  }

  private extractedUserInfoAndDeviceInfo(userService: UserService, angularFireAnalytics: AngularFireAnalytics) {
    if (this.platformService.isPlatformNative()) {
      userService.currentUserState$
        .pipe(
          filter<any>(Boolean),
          tap((user: AuthenticationInformation) => {
              forkJoin([App.getInfo(), Device.getId(), Device.getInfo()]).subscribe((results: any[]) => {
                const appInfo: AppInfo = results[0]
                const deviceId: DeviceId = results[1]
                const deviceInfo: DeviceInfo = results[2]
                angularFireAnalytics.setUserProperties({
                  appBuild: appInfo.build,
                  appId: appInfo.id,
                  appName: appInfo.name,
                  appVersion: appInfo.version,
                  isVirtual: deviceInfo.isVirtual,
                  manufacturer: deviceInfo.manufacturer,
                  model: deviceInfo.model,
                  operatingSystem: deviceInfo.operatingSystem,
                  osVersion: deviceInfo.osVersion,
                  platform: deviceInfo.platform,
                  uuid: deviceId.identifier,
                  code_emploi_gestion: user.management_code,
                  user_id: user.nameid,
                  role: user.role_id,
                  privileges: user.roles,
                  department_code: user.department_code,
                })
              })
            },
          ),
        )
        .subscribe()
    } else {
      userService.currentUserState$
        .pipe(
          filter<any>(Boolean),
          tap((user: AuthenticationInformation) => {
              forkJoin([Device.getId(), Device.getInfo()]).subscribe((results: any[]) => {
                const deviceId: DeviceId = results[0]
                const deviceInfo: DeviceInfo = results[1]
                angularFireAnalytics.setUserProperties({
                  isVirtual: deviceInfo.isVirtual,
                  manufacturer: deviceInfo.manufacturer,
                  model: deviceInfo.model,
                  operatingSystem: deviceInfo.operatingSystem,
                  osVersion: deviceInfo.osVersion,
                  platform: deviceInfo.platform,
                  uuid: deviceId.identifier,
                  code_emploi_gestion: user.management_code,
                  user_id: user.nameid,
                  role: user.role_id,
                  privileges: user.roles,
                  department_code: user.department_code,
                })
              })
            },
          ),
        )
        .subscribe()
    }
  }

  handleRootHashChange = async (e: NavigationEnd) => {
    this.pageName.next(this.urlService.getUrlPageName(e.url))
    await this.angularFireAnalytics.logEvent('screen_view', {
      page_title: this.pageName.getValue(),
      screen_name: e.url,
    })
  }

  public pushLogEvent(o: { eventName: string; eventParameter: {} }): void {
    this.pageName
      .pipe(
        take(1),
        filter((e) => !!e),
        tap((page) => {
          const params = { ...o.eventParameter, page_title: page }
          this.angularFireAnalytics.logEvent(o.eventName, params).then()
        }),
      )
      .subscribe()
  }

  async pushLogoutEvent() {
    this.pushLogEvent({ eventName: 'log_out', eventParameter: {} })
  }
}
