import Phaser from 'phaser'
import { ColorPhaser } from '../../../modeles/color'
import { TimeDisplayElementOption } from './models/time-display-element-option.model'
import { TIME_WINDOW_NUMBER_TEXT_FONT, TIME_WINDOW_NUMBER_TEXT_SIZE } from '../../../configs/configuration.text'
import { SelectedPlatformInfo } from '../../../../app/core/configurations/user/selected.platform.info'
import { Platform, TrafficStateType } from '../../../../app/core/models/platform-info-traffic'
import { SecondsToMinutesPipe } from '../../../../app/shared/pipe/seconds-to-minutes.pipe'

const WINDOW_TEXT_INFORMATION = 'WINDOW_TEXT_INFORMATION'

export class PlatformDisplayText extends Phaser.GameObjects.BitmapText {

  private previousColor: ColorPhaser
  private previousText: string

  constructor(scene: Phaser.Scene, timeDisplayElementOption: TimeDisplayElementOption) {
    const PLATFORM_TEXT_Y_OFFSET = -3
    const PLATFORM_TEXT_X_OFFSET = 22

    super(scene, PLATFORM_TEXT_X_OFFSET, PLATFORM_TEXT_Y_OFFSET, TIME_WINDOW_NUMBER_TEXT_FONT, '', TIME_WINDOW_NUMBER_TEXT_SIZE)

    this.name = WINDOW_TEXT_INFORMATION
    this.setVisible(timeDisplayElementOption.visible)
    this.setOrigin(0.5, -0.3)
  }

  updateTextDisplay(selectedChoice: SelectedPlatformInfo, platform: Platform = null) {
    switch (selectedChoice) {
      case SelectedPlatformInfo.NONE:
        this.setVisible(false)
        break
      case SelectedPlatformInfo.ELAPSED:
        this.setVisible(true)
        this.updateTimeIfNeeded(platform.elapsedTime, platform.trafficState)
        this.updateColorIfNeeded(ColorPhaser.ELAPSED_INFORMATION)
        break
      case SelectedPlatformInfo.WAITING:
        this.setVisible(true)
        this.updateTimeIfNeeded(platform.timeToWait, platform.trafficState)
        this.updateColorIfNeeded(ColorPhaser.WAITING_INFORMATION)
        break
      case SelectedPlatformInfo.TARGET:
        this.setVisible(true)
        this.updateTimeIfNeeded(platform.waitingTimeTarget, platform.trafficState)
        this.updateColorIfNeeded(ColorPhaser.STATION_NAME)
        break
    }
  }

  private updateTimeIfNeeded(time: number, trafficState: TrafficStateType) {
    const text = trafficState === TrafficStateType.INTERRUPTED ? '/' : new SecondsToMinutesPipe().transform(time)
    if (text !== this.previousText) {
      this.setText(text)
      this.previousText = text
    }
  }

  private updateColorIfNeeded(color: ColorPhaser) {
    if (!!color && color !== this.previousColor) {
      this.setTint(Number(color.valueOf()))
      this.previousColor = color
    }
  }
}