import { Directive, OnDestroy } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { SnackBarComponent } from '../snake-bar/snack-bar.component'
import { paths } from '../../../environments/paths'

export enum ConnectionState {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

@Directive({
  selector: '[appWindowListener]',
})
export class WindowListenerDirective implements OnDestroy {
  private firstConnectionState = false
  private previousState = true
  private messageConnectionState = 'app.shared.window.listener.message.connection.state.'
  private navigatorConnectionStateSubject: BehaviorSubject<ConnectionState> = new BehaviorSubject<ConnectionState>(window.navigator.onLine ? ConnectionState.ONLINE : ConnectionState.OFFLINE)
  private navigatorState: Subject<ConnectionState> = new Subject<ConnectionState>()
  private readonly interval: NodeJS.Timeout

  constructor(private snackBar: SnackBarComponent) {
    this.interval = setInterval(() => this.handleConnection(), 5000)
  }

  handleConnection() {
    if (navigator.onLine) {
      this.isReachable(this.getServerUrl()).then((online) => {
        this.navigatorState.next(online ? ConnectionState.ONLINE : ConnectionState.OFFLINE)
        if ((this.firstConnectionState && !online) || this.previousState !== Boolean(online)) {
          this.snackBar.messageSubject.next(this.messageConnectionState + (online ? ConnectionState.ONLINE : ConnectionState.OFFLINE))
        }
        this.previousState = !!online
        this.firstConnectionState = false
      })
    } else {
      this.snackBar.messageSubject.next(this.messageConnectionState + ConnectionState.OFFLINE)
      this.previousState = false
      this.firstConnectionState = false
    }
  }

  isReachable(url) {
    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
      .then((resp) => {
        return resp && (resp.ok || resp.type === 'opaque')
      })
      .catch((err) => {
        console.warn('[conn test failure]:', err)
      })
  }

  getServerUrl() {
    return paths.actuator
  }

  ngOnDestroy(): void {
    this.navigatorConnectionStateSubject.unsubscribe()
    this.navigatorState.unsubscribe()
    clearInterval(this.interval)
  }
}
