import 'phaser'
import { AttendanceLevel, AttendanceLevelType, Train } from '../../../modeles/trains'
import { AbstractGroupElement } from '../abstract.group.element'
import PathFollowerPlugin from 'phaser3-rex-plugins/plugins/pathfollower-plugin.js'
import { MainScene } from '../../../scenes/main.scene'
import { tweenTrainConfiguration } from '../../../configs/tween.train.configuration'
import { ContainerDelayDisplayElement } from '../delay/delay.container'
import { ContainerTrackElement } from '../track/track.element'
import { TrainType } from '../../../modeles/line.type.model'
import { ColorPhaser } from '../../../modeles/color'
import {
  ATTENDANCE_BACKGROUND_HEIGHT,
  ATTENDANCE_OFFSET_Y,
  TRAIN_ELEMENTS_OFFSET_X,
  TRAIN_INTERACTIVE_OFFSET_X,
  TRAIN_INTERACTIVE_OFFSET_Y,
} from './train.view.config'
import { userPreferences } from '../../../../app/core/configurations/user/user.preferences'
import { TRAIN_NUMBER_TEXT_FONT, TRAIN_NUMBER_TEXT_SIZE } from '../../../configs/configuration.text'
import { SvgImagesType } from '../../../managers/image.manager'
import RoundRectangle from 'phaser3-rex-plugins/plugins/roundrectangle.js'
import { Scene } from 'phaser'
import GesturesPlugin from 'phaser3-rex-plugins/plugins/gestures-plugin.js'

// Destroy from phaser3-rex-plugins throws useless errors - This code is temporary until phaser3-rex-plugin fix the bug
const originalDestroy = GesturesPlugin.prototype.destroy
GesturesPlugin.prototype.destroy = function() {
  try {
    originalDestroy.apply(this)
  } catch (error) {
    if (error.message !== 'this.add.destroy is not a function') {
      throw error
    }
  }
}

function definedTrainElementImage(train: Train, trainType: TrainType, trackPosition: number): string {
  let svgImage: SvgImagesType
  switch (trainType) {
    case TrainType.METRO:
      switch (trackPosition) {
        case 1:
          svgImage = 'METRO_RIGHT'
          break
        case 2:
          svgImage = 'METRO_LEFT'
          break
      }
      break

    case TrainType.RER:
      switch (trackPosition) {
        case 1:
          svgImage = 'RER_LEFT'
          break
        case 2:
          svgImage = 'RER_RIGHT'
          break
      }
      break
  }

  return svgImage
}

export function getTrainTransportColor(train) {
  switch (train.transportStatusType) {
    case 'FULL_PASSENGER':
      return ColorPhaser.BLACK
    case 'NO_PASSENGER':
    case 'UNIDENTIFIED':
      return ColorPhaser.NO_PASSENGER
    default:
      return ColorPhaser.BLACK
  }
}

export function getAttendanceColor(attendanceLevel) {
  switch (attendanceLevel) {
    case AttendanceLevel.LOW:
    case AttendanceLevel.MEDIUM:
      return ColorPhaser.ATTENDANCE_LOW
    case AttendanceLevel.HIGH:
    case AttendanceLevel.VERY_HIGH:
      return ColorPhaser.ATTENDANCE_HIGH
    case AttendanceLevel.FULL:
      return ColorPhaser.ATTENDANCE_FULL
    default:
      return ColorPhaser.BLACK
  }
}

export function getAttendanceBackgroundColor(attendanceLevel) {
  switch (attendanceLevel) {
    case AttendanceLevel.NO_INFO:
      return ColorPhaser.BLACK
    case AttendanceLevel.LOW:
    case AttendanceLevel.MEDIUM:
      return ColorPhaser.ATTENDANCE_LOW_BACKGROUND
    case AttendanceLevel.HIGH:
    case AttendanceLevel.VERY_HIGH:
      return ColorPhaser.ATTENDANCE_HIGH_BACKGROUND
    default:
      return ColorPhaser.ATTENDANCE_FULL
  }
}

export function getTrainColor(train: Train): number {
  if (train.overParkingInfo && train.overParkingInfo.overParked) {
    return ColorPhaser.OVER_PARKING
  }
  switch (train.terminusColor) {
    case 'BLUE':
      return ColorPhaser.BLUE_DESTINATION
    case 'YELLOW':
      return ColorPhaser.YELLOW_DESTINATION
    default:
      return ColorPhaser.TIME_DISPLAY
  }
}

export function getTrainLineWidth(train: Train): number {
  if (train.overParkingInfo && train.overParkingInfo.overParked) {
    return 5
  }
  return 3
}

export function getHeight(attendanceLevel: AttendanceLevelType): number {
  const level = Object.keys(AttendanceLevel).length - 1
  switch (attendanceLevel) {
    case AttendanceLevel.NO_INFO:
      return 0
    case AttendanceLevel.LOW:
      return ATTENDANCE_BACKGROUND_HEIGHT / level
    case AttendanceLevel.MEDIUM:
      return (ATTENDANCE_BACKGROUND_HEIGHT / level) * 2
    case AttendanceLevel.HIGH:
      return (ATTENDANCE_BACKGROUND_HEIGHT / level) * 3
    case AttendanceLevel.VERY_HIGH:
      return (ATTENDANCE_BACKGROUND_HEIGHT / level) * 4
    default:
      return ATTENDANCE_BACKGROUND_HEIGHT
  }
}

export function getAttendanceYPosition(attendanceLevel: AttendanceLevelType): number {
  return ATTENDANCE_OFFSET_Y - getHeight(attendanceLevel) / 2
}

export function trainElementBackGround(scene: Scene, train: Train, trainType: TrainType): RoundRectangle {
  const metroWidth = 28
  const metroHeight = 30
  const rerWidth = 53
  const rerHeight = 30
  const stroke = 3
  const radius = 5
  const xPosition = 0
  const yPosition = 0
  const color = getTrainTransportColor(train)

  const newTrainElement = createRoundRectangle(scene, train, trainType, metroWidth, metroHeight, rerWidth, rerHeight, radius, xPosition, yPosition, color)
  newTrainElement.setStrokeStyle(stroke, getTrainColor(train))
  return newTrainElement
}

export function trainElementAttendance(scene: Scene, train: Train, trainType: TrainType): RoundRectangle {
  const metroWidth = 20 // 28
  const metroHeight = getHeight(train.attendanceLevel) // 30
  const rerWidth = 45
  const rerHeight = 30
  const radius = 2
  const xPosition = 0
  const yPosition = getAttendanceYPosition(train.attendanceLevel)
  const color = getAttendanceColor(train.attendanceLevel)
  return createRoundRectangle(scene, train, trainType, metroWidth, metroHeight, rerWidth, rerHeight, radius, xPosition, yPosition, color)
}

export function trainElementAttendanceBackground(scene: Scene, train: Train, trainType: TrainType): RoundRectangle {
  const metroWidth = 20
  const metroHeight = ATTENDANCE_BACKGROUND_HEIGHT
  const rerWidth = 45
  const rerHeight = 30
  const radius = 4
  const xPosition = 0
  const yPosition = 0
  const color = getAttendanceBackgroundColor(train.attendanceLevel)
  return createRoundRectangle(scene, train, trainType, metroWidth, metroHeight, rerWidth, rerHeight, radius, xPosition, yPosition, color)
}

export function createRoundRectangle(scene: Scene, train: Train, trainType: TrainType, metroWidth: number, metroHeight: number, rerWidth: number, rerHeight: number, radius: number, xPosition: number, yPosition: number, color: number): RoundRectangle {
  const newTrainElement = new RoundRectangle(scene, xPosition, yPosition, trainType === TrainType.METRO ? metroWidth : rerWidth, trainType === TrainType.METRO ? metroHeight : rerHeight, radius)
  newTrainElement.setFillStyle(color)
  newTrainElement.setX(TRAIN_ELEMENTS_OFFSET_X)
  return newTrainElement
}

export class TrainElement extends Phaser.GameObjects.Image {
  constructor(scene: Phaser.Scene, private train: Train, trainType: TrainType, trackNumber: number) {
    super(scene, 0, 0, definedTrainElementImage(train, trainType, trackNumber))
    this.scale = 1
    this.setTint(getTrainColor(train))
    this.setX(TRAIN_ELEMENTS_OFFSET_X)
  }
}

export class TrainElementText extends Phaser.GameObjects.BitmapText {
  constructor(scene: Phaser.Scene, train: Train) {
    super(scene, 0, 0, TRAIN_NUMBER_TEXT_FONT, train.number, TRAIN_NUMBER_TEXT_SIZE)
    this.setOrigin(0.5, 0.46)
    this.setTint(train.transportStatusType === 'NO_PASSENGER' || train.transportStatusType === 'UNIDENTIFIED' ? ColorPhaser.GREY_TRAIN_TEXT : ColorPhaser.WHITE)
    this.setX(TRAIN_ELEMENTS_OFFSET_X)
  }
}

/**
 * groupement de la rame avec son id element relatif
 */
export class ContainerTrainElement extends Phaser.GameObjects.Container {
  private readonly pathFollowerPlugin: PathFollowerPlugin
  private readonly _containerDelayDisplayElement: ContainerDelayDisplayElement
  private readonly _trainElement: TrainElement
  private readonly _trainElementBackground: RoundRectangle
  private _trainElementAttendance: RoundRectangle
  private _trainElementAttendanceBackground: RoundRectangle
  private readonly _trainElementText: TrainElementText
  private readonly _id: string
  private tween: Phaser.Tweens.Tween
  private readonly _trackNumber: number

  private _train: Train

  constructor(scene: Phaser.Scene, train: Train, coordinates: {
    x: number;
    y: number
  }, trainType: TrainType, trackNumber: number, isAffluenceDisplayed: boolean) {
    super(scene, coordinates.x, coordinates.y)
    this._train = train
    this._id = train.number
    this._trackNumber = trackNumber

    // Children

    this._containerDelayDisplayElement = new ContainerDelayDisplayElement(this.scene, train, userPreferences.selectedTrainDelay, trainType, trackNumber)
    this._trainElement = new TrainElement(scene, train, trainType, this._trackNumber)
    this._trainElementText = new TrainElementText(scene, train)
    this._trainElementBackground = trainElementBackGround(scene, train, trainType)

    if (train.attendanceLevel !== 'NO_INFO' && isAffluenceDisplayed) {
      this._trainElementAttendance = trainElementAttendance(scene, train, trainType)
      this._trainElementAttendanceBackground = trainElementAttendanceBackground(scene, train, trainType)
    } else {
      // just hide attendance and its background.
      const attendance = trainElementAttendance(scene, train, trainType)
      attendance.resize(0, 0)
      const attendanceBackground = trainElementAttendanceBackground(scene, train, trainType)
      attendanceBackground.resize(0, 0)

      if (train.transportStatusType === 'FULL_PASSENGER') {
        attendance.setFillStyle(ColorPhaser.BLACK)
        attendanceBackground.setFillStyle(ColorPhaser.BLACK)
      } else if (train.transportStatusType === 'NO_PASSENGER' || train.transportStatusType === 'UNIDENTIFIED') {
        attendance.setFillStyle(ColorPhaser.NO_PASSENGER)
        attendanceBackground.setFillStyle(ColorPhaser.NO_PASSENGER)
      }

      this._trainElementAttendance = attendance
      this._trainElementAttendanceBackground = attendanceBackground
    }

    const layers = [this._trainElement, this._trainElementBackground, this._trainElementAttendanceBackground, this._trainElementAttendance, this._containerDelayDisplayElement, this._trainElementText]

    this.add(layers)

    this.pathFollowerPlugin = scene.plugins.get('rexPathFollower') as PathFollowerPlugin
    // Permet l'interaction d'une rame
    this.setInteractive(new Phaser.Geom.Rectangle(TRAIN_INTERACTIVE_OFFSET_X, TRAIN_INTERACTIVE_OFFSET_Y, 30, 30), Phaser.Geom.Rectangle.Contains)
    this.on('pointerover', () => scene.input.setDefaultCursor('pointer'))
    this.on('pointerout', () => scene.input.setDefaultCursor('default'))
  }

  set train(value: Train) {
    this._train = value
  }

  get trackNumber(): number {
    return this._trackNumber
  }

  get id() {
    return this._id
  }

  get containerDelayDisplayElement(): ContainerDelayDisplayElement {
    return this._containerDelayDisplayElement
  }

  get trainElement(): TrainElement {
    return this._trainElement
  }

  get trainElementBackground(): RoundRectangle {
    return this._trainElementBackground
  }

  get trainElementAttendance(): RoundRectangle {
    return this._trainElementAttendance
  }

  set trainElementAttendance(valueRoundRectangle) {
    this._trainElementAttendance = valueRoundRectangle
  }

  get trainElementAttendanceBackground(): RoundRectangle {
    return this._trainElementAttendanceBackground
  }

  set trainElementAttendanceBackground(valueRoundRectangle) {
    this._trainElementAttendanceBackground = valueRoundRectangle
  }

  get train(): Train {
    return this._train
  }

  moveToTheNextTrackWithAnimation(track: ContainerTrackElement, scene: MainScene) {
    if (this.tween) {
      scene.tweens.remove(this.tween)
    }
    if (this.x !== track.x || this.y !== track.positionYOfCdv) {
      const path = new Phaser.Curves.Path(this.x, this.y)
      path.lineTo(track.x, track.positionYOfCdv)
      const pathFollower = this.pathFollowerPlugin?.add(this, { path })
      this.tween = scene.tweens.add(tweenTrainConfiguration(pathFollower as any))
    }
  }

  destroy(fromScene?: boolean) {
    super.destroy(fromScene)
    if (this.tween) {
      this.tween.stop()
    }
  }
}

/**
 * groupement des éléments qui forment un ensemble de trains
 */
export class GroupTrainsElement extends AbstractGroupElement {
  getAllContainerTrainElement(): ContainerTrainElement[] {
    return super.getChildren().map((child) => child as ContainerTrainElement)
  }

  /**
   * on supprime tout les sprites qui ne sont plus dans le message stomp
   * containerTrainElement
   */
  clearContainerTrainElement(containerTrainElement: ContainerTrainElement): void {
    this.remove(containerTrainElement, true, true)
  }

  public getGroupedContainerById(): Map<string, ContainerTrainElement[]> {
    const map: Map<string, ContainerTrainElement[]> = new Map<string, ContainerTrainElement[]>()
    this.getAllContainerTrainElement().forEach((container) => {
      let containerTrainElements: ContainerTrainElement[] = map.get(container.id)
      if (containerTrainElements == null) {
        containerTrainElements = new Array<ContainerTrainElement>()
        map.set(container.id, containerTrainElements)
      }
      containerTrainElements.push(container)
    })
    return map
  }
}
