import { MainScene } from '../scenes/main.scene'

export class SceneController {
  static DURATION_PAN_CAMERA = 0

  private scene: MainScene

  constructor(scene: MainScene) {
    this.scene = scene
  }

  scrollCameraToCenterOfTheMap(positionX: number, positionY: number) {
    const mainCamera = this.scene.cameras.main
    mainCamera.pan(positionX, positionY, SceneController.DURATION_PAN_CAMERA)
  }

  resetPinch() {
    const mainCamera = this.scene.cameras.main
    mainCamera.zoom = 1
  }

  disableCameraControls() {
    const mainCamera = this.scene.cameras.main;

    // Désactiver les événements associés aux contrôles de la caméra
    this.scene.input.removeAllListeners('wheel'); // Désactive le zoom à la molette
    this.scene.input.removeAllListeners('pointermove'); // Désactive le drag

    // force la caméra à ne plus répondre aux commandes utilisateur
    mainCamera.setScroll(mainCamera.scrollX, mainCamera.scrollY); // Garde la caméra en place
  }

}
