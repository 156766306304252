import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { from, Observable } from 'rxjs'
import { flatMap } from 'rxjs/operators'
import { LocalStorageService } from '../local-storage.service'
import { environment } from '../../../environments/environment'
import { appProperties } from '../../../environments/app.properties'

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.localStorageService.getItem(appProperties.token)).pipe(
      flatMap((token) => {
        req = req.clone({
          setHeaders: {
            Authorization: token.value ? token.value : '',
          },
        })
        if (!environment.production) {
          console.log(req)
        }
        return next.handle(req)
      })
    )
  }
}
