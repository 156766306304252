import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UrlService } from './core/services/url.service'
import { environment } from '../environments/environment'
import {Platform} from '@ionic/angular'
import { PlatformService } from './core/services/platform.service'
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx'
import { FcmService } from './core/services/notifications/fcm.service'
import { TitleService } from './core/services/title.service'
import { UserService } from './core/security/user.service'
import { FullscreenService } from './core/services/fullscreen.service'
import { BehaviorSubject, Subscription } from 'rxjs'
import { Display } from './shared/full-screen-button/full-screen-button.component'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  fullscreenStateSubscription: Subscription
  fullscreenState: BehaviorSubject<Display> = new BehaviorSubject<Display>('NORMAL')

  constructor(
    public platform: Platform,
    public elementRef: ElementRef,
    public router: Router,
    private platformService: PlatformService,
    private screenOrientation: ScreenOrientation,
    private fcmService: FcmService, // don't remove
    public titleService: TitleService,
    public userService: UserService,
    public urlService: UrlService,
    private fullscreenService: FullscreenService
  ) {
    this.initializeApp().then()
    // HACK: fix toast not presented when offline, due to lazy loading the toast controller.
    // Can be removed once #17450 is resolved: https://github.com/ionic-team/ionic/issues/17450
  }
  async initializeApp() {
    if (!environment.production) {
      console.table(this.platform.platforms())
    }
    await this.platform.ready()
    if (this.platformService.isPlatformNative() && !this.platformService.isPlatformTablet()) {
      await this.screenOrientation.lock('portrait')
    }
    if (this.platformService.isPlatformNative()) {
      await this.fcmService.removeAllDeliveredNotifications()
      if ( this.platformService.isPlatformIos()) {
        await this.fcmService.createMobileToken()
      }
    }
  }

  ngOnInit(): void {
    this.fullscreenStateSubscription = this.fullscreenService.getFullscreenState().subscribe(async (fullscreenState) => {
      this.fullscreenState.next(fullscreenState)
    })
  }

  ngOnDestroy(): void {
    this.fullscreenStateSubscription.unsubscribe()
  }
}
