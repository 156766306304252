export enum URL_CONFIG {
  URL_ROOT = '/',
  URL_REAL_TIME_TRAFFIC = '/real-time-traffic',
  URL_FUTURE_TERMINAL = '/real-time-traffic-fullscreen',
  URL_REAL_TIME_TRAFFIC_MAP = '/real-time-traffic/map',
  URL_REAL_TIME_TRAFFIC_NEXT_DEPARTURES = '/real-time-traffic/next-departures',
  URL_TCO_NETWORK = '/tco-network',
  URL_PRODUCTION_DASHBOARD = '/production-dashboard',
  URL_INTERVAL_DASHBOARD = '/interval-dashboard',
  URL_DISRUPTION_HISTORY = '/disruptions-history',
  URL_INFORMATION_TRAFFIC = '/information-traffic',
  URL_PROFILE = '/profile',
  URL_GDPR = '/gdpr',
  URL_NOTIFICATION_PAGE = '/notifications',
  URL_NOTIFICATION_AVERAGE_INTERVAL = '/average-interval',
  URL_NOTIFICATION_STATIONS_STATE = '/stations-state',
  URL_NOTIFICATION_TRAFFIC_INTERRUPTION = '/traffic-interruption',
  URL_NOTIFICATION_OVER_PARKING = '/over-parking',
  URL_LOGIN = '/login',
  URL_RESTRICTED_ACCESS = '/restricted-access',
  URL_NOTIFICATION_INTERVAL = '/notifications/average-interval',
  URL_NOTIFICATION_STATION_STATE = '/notifications/stations-state',
  URL_NOTIFICATION_TRAFFIC_INTERRUPTION_PATH = '/notifications/traffic-interruption',
  URL_HELP = '/help',
}

export enum URL_NAME {
  // page name

  URL_ROOT_NAME = 'Trafic en temps réel',
  URL_REAL_TIME_TRAFFIC_NAME = 'Trafic en temps réel',
  URL_REAL_TIME_TRAFFIC_MAP_NAME = 'Trafic en temps réel',
  URL_REAL_TIME_TRAFFIC_NEXT_DEPARTURES_NAME = 'Prochains départs',
  URL_TCO_NETWORK_NAME = 'État du réseau (TCO)',
  URL_PRODUCTION_DASHBOARD_NAME = 'Prod HdP',
  URL_INTERVAL_DASHBOARD_NAME = 'Intervalles',
  URL_DISRUPTION_HISTORY_NAME = 'Flash PG',
  URL_PROFILE_NAME = 'Mon profil',
  URL_GDPR_NAME = 'Mentions légales',
  URL_NOTIFICATION_PAGE_NAME = 'Notifications',
  URL_NOTIFICATION_AVERAGE_INTERVAL_NAME = 'Intervalle moyen',
  URL_NOTIFICATION_STATIONS_STATE_NAME = 'Fermeture de station',
  URL_LOGIN_NAME = 'Login',
  URL_RESTRICTED_ACCESS_NAME = 'Non autorisé',
  URL_NOTIFICATION_INTERVAL_NAME = 'Notification des intervalles',
  URL_NOTIFICATION_STATION_STATE_NAME = 'Notification des stations fermées',
  URL_NOTIFICATION_TRAFFIC_INTERRUPTION_PATH_NAME = 'Notification des interruptions de trafic',
  URL_HELP_NAME = '/help',
}
