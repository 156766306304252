import { Injectable } from '@angular/core'
import { URL_CONFIG, URL_NAME } from '../configurations/urls'
import { BehaviorSubject, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  public liveTrafficUrls: typeof URL_CONFIG = URL_CONFIG
  public liveTrafficNames: typeof URL_NAME = URL_NAME
  public destroyLineMappingPhaserFullscreen = new Subject<void>()
  public destroyLineMappingPhaser = new Subject<void>()

  getUrlPageName(url: string): string {
    const liveTrafficUrl = Object.keys(this.liveTrafficUrls).filter((name) => this.liveTrafficUrls[name] === url)
    return this.liveTrafficNames[liveTrafficUrl + '_NAME']
  }

}
