import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { from, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { URL_CONFIG } from '../configurations/urls'
import { LocalStorageService } from '../local-storage.service'

@Injectable({
  providedIn: 'root',
})
export class CanActivateRestrictedGrantedAccessGuard  {
  constructor(private localStorage: LocalStorageService, private router: Router) {}

  /**
   * vérification des roles du token,
   * la validité est faite par le chargement de la donnée ou de 'initialisation et donc toute les secondes au moins
   * route
   * state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.isAuthenticated()
  }

  isAuthenticated() {
    return from(this.localStorage.getCurrentUser()).pipe(
      take(1),
      map((user) => (!user ? this.router.parseUrl(URL_CONFIG.URL_LOGIN) : true))
    )
  }
}
