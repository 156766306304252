import { Injectable } from '@angular/core'
import { CONFIGURATION_MOBILE } from '../../../phaser/configs/configuration.mobile'
import { CONFIGURATION_WEB_APP } from '../../../phaser/configs/configuration.webApp'
import { CONFIGURATION_TABLET } from '../../../phaser/configs/configuration.tablet'
import 'phaser'
import { PlatformService } from '../services/platform.service'
import { MainScene } from '../../../phaser/scenes/main.scene'

@Injectable()
export class GameConfigurationService {
  constructor(private platformService: PlatformService) {
  }

  getGameConfiguration(parentConfiguration: string, mainScene: MainScene): Phaser.Types.Core.GameConfig {
    let configuration: Phaser.Types.Core.GameConfig
    switch (true) {
      case this.platformService.isPlatformMobile():
        configuration = CONFIGURATION_MOBILE
        break
      case this.platformService.isPlatformTablet():
        configuration = CONFIGURATION_TABLET
        break
      case this.platformService.isPlatformDesktop():
        configuration = CONFIGURATION_WEB_APP
        break
      default:
        configuration = CONFIGURATION_WEB_APP
    }
    return { ...configuration, parent: parentConfiguration, scene: [mainScene] }
  }
}
