import 'phaser'
import { phaserPlugins } from './phaser.plugins'

export const CONFIGURATION_TABLET = {
  type: Phaser.AUTO,
  backgroundColor: '#F4F4F4',
  title: 'liveTrafic-tablet',
  autoCenter: Phaser.Scale.Center.CENTER_BOTH,
  _resolution: window.devicePixelRatio,
  scale: {
    mode: Phaser.Scale.RESIZE,
  },
  plugins: phaserPlugins,
  render: {
    roundPixels: true,
  },
}
