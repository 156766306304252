import { ElementRef, Injectable } from '@angular/core'
import { CDV_STATE, CdvRer, SWITCH_STATE, TcoCdv, TcoCdvRer, TcoInformation, TcoSubSection, TcoSwitch, TcoTrafficLight, TRAFFIC_LIGHT_STATE } from '../models/tco'
import { TCO_COLORS } from '../models/tco-colors'

export enum TCO_STYLE {
  COLOR_SPOT = 'fill',
}

export enum CDV_TAG_NAME {
  ID_SUFFIX = 'cdv',
  CDV = '{0}cdv',
  MISSION = 'CDV{0}mission',
  SHAPE = 'CDV{0}rect',
}

export enum SUB_SECTION_TAG_NAME {
  SUB_SECTION = '{0}SS',
  CODE_PLAN = 'SS{0}codeplan',
  SHAPE = 'SS{0}rect',
}

export enum TRAFFIC_LIGHT_TAG_NAME {
  TRAFFIC_LIGHT = '{0}feu',
  RED_LIGHT = '{0}FEU_ROUGE',
  GREEN_LIGHT = '{0}FEU_VERT',
}

export enum SWITCH_TAG_NAME {
  SWITCH = '{0}aig',
  LEFT = '{0}AIG_ITI_GAUCHE',
  RIGHT = '{0}AIG_ITI_DROITE',
  TIP = '{0}AIG_POINTE',
}

export function FormatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index])
  }
  return str
}

export class ElementOccupied {
  public element: Element
  public trainNumber: string
  public id: string
}

@Injectable({
  providedIn: 'root',
})
export class TcoLineMappingService {
  private counter = 0
  private listTrainNumber: string[] = []
  private listCDVOccupied: ElementOccupied[] = []

  constructor() {}

  handleTcoCdvLineMappingChange(svgDisplayComponent: ElementRef): (tcoInformation: TcoInformation) => void {
    return (tcoInformation: TcoInformation) => {
      this.tcoCdvChange(svgDisplayComponent, tcoInformation.tcoCdv)
    }
  }

  handleTcoCdvRerLineMappingChange(svgDisplayComponent: ElementRef): (tcoInformation: TcoInformation) => void {
    return (tcoInformation: TcoInformation) => {
      if (this.counter === 5) {
        this.tcoCdvRerChange(svgDisplayComponent, tcoInformation.tcoCdvRer)
        this.counter = 0
      } else {
        this.counter++
      }
    }
  }

  handleTcoTrafficLightLineMappingChange(svgDisplayComponent: ElementRef): (tcoInformation: TcoInformation) => void {
    return (tcoInformation: TcoInformation) => {
      this.tcoTrafficLightChange(svgDisplayComponent, tcoInformation.tcoTrafficLight)
    }
  }

  handleTcoSubSectionLineMappingChange(svgDisplayComponent: ElementRef): (tcoInformation: TcoInformation) => void {
    return (tcoInformation: TcoInformation) => {
      this.tcoSubSectionChange(svgDisplayComponent, tcoInformation.tcoSubSection)
    }
  }

  handleTcoSwitchLineMappingChange(svgDisplayComponent: ElementRef): (tcoInformation: TcoInformation) => void {
    return (tcoInformation: TcoInformation) => {
      this.tcoSwitchChange(svgDisplayComponent, tcoInformation.tcoSwitch)
    }
  }

  tcoCdvChange(svgDisplayComponent: ElementRef, tcoCdv: TcoCdv) {
    if (tcoCdv) {
      tcoCdv.cdvs.forEach((cdv) => {
        const id = cdv.id.toString()
        const cdvTag = document.getElementById(FormatString(CDV_TAG_NAME.CDV, id))

        if (cdvTag) {
          const rect = document.getElementById(FormatString(CDV_TAG_NAME.SHAPE, id))
          const mission = document.getElementById(FormatString(CDV_TAG_NAME.MISSION, id))

          if (!!rect && !!mission) {
            if (cdv.state === CDV_STATE.OCCUPIED) {
              rect.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[CDV_STATE.OCCUPIED])
              mission.textContent = cdv.trainNumber
            } else if (cdv.state === CDV_STATE.FREE) {
              rect.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[cdv.state])
              mission.textContent = ''
            }
          }
        }
      })
    }
  }

  tcoCdvRerChange(svgDisplayComponent: ElementRef, tcoCdv: TcoCdvRer) {
    if (tcoCdv) {
      this.listTrainNumber = []
      this.listCDVOccupied = []
      tcoCdv.cdvs.forEach((cdv) => {
        let id = cdv.id.toString()
        const cdvTag = document.getElementById(FormatString(CDV_TAG_NAME.CDV, id))

        let element: Element
        if (cdvTag) {
          element = cdvTag
        } else {
          const cdvClasses = document.getElementsByClassName(id)
          if (!!cdvClasses && !!cdvClasses.item(0)) {
            element = cdvClasses.item(0)
            id = parseInt(element.id, 10).toString()
          }
        }

        if (element) {
          if (cdv.state === CDV_STATE.OCCUPIED) {
            const elementOccupied: ElementOccupied = new ElementOccupied()
            elementOccupied.element = element
            elementOccupied.trainNumber = cdv.trainNumber
            elementOccupied.id = id
            this.listCDVOccupied.push(elementOccupied)
          }
          this.updateCdvRer('rect', element, cdv)
          this.updateCdvRer('polygon', element, cdv)
          this.updateCdvMission(id, cdv)
        }
      })
      let elt: ElementOccupied
      this.listTrainNumber = []
      for (elt of this.listCDVOccupied) {
        this.updateCdvRerOccupied('rect', elt.element)
        this.updateCdvRerOccupied('polygon', elt.element)
        this.updateCdvMissionOccupied(elt.id, elt.trainNumber)
      }
    }
  }

  private updateCdvRerOccupied(tagName: string, cdvTag: Element) {
    const rectTags = cdvTag.getElementsByTagName(tagName)
   
    for (let _i = 0; _i < rectTags.length; _i++) {
      rectTags[_i].setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[CDV_STATE.OCCUPIED])
    }
  }

  private updateCdvMissionOccupied(id: string, trainNumber: string) {
    if (this.listTrainNumber.indexOf(trainNumber) < 0) {
      const mission = document.getElementById(FormatString(CDV_TAG_NAME.MISSION, id))
      if (mission) {
        this.listTrainNumber.push(trainNumber)
        mission.textContent = trainNumber
      }
    }
  }

  private updateCdvRer(tagName: string, cdvTag: Element, cdv: CdvRer) {
    const rectTags = cdvTag.getElementsByTagName(tagName)
   
    for (let _i = 0; _i < rectTags.length; _i++) {
      rectTags[_i].setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[cdv.state])
    }
  }

  private updateCdvMission(id: string, cdv: CdvRer) {
    const mission = document.getElementById(FormatString(CDV_TAG_NAME.MISSION, id))
    if (mission) {
      mission.textContent = ''
    }
  }

  tcoTrafficLightChange(svgDisplayComponent: ElementRef, tcoTrafficLight: TcoTrafficLight) {
    if (tcoTrafficLight) {
      tcoTrafficLight.trafficLights.forEach((trafficLight) => {
        const id = trafficLight.id.toString()
        const trafficLightTag = document.getElementById(FormatString(TRAFFIC_LIGHT_TAG_NAME.TRAFFIC_LIGHT, id))

        if (trafficLightTag) {
          const redLight = document.getElementById(FormatString(TRAFFIC_LIGHT_TAG_NAME.RED_LIGHT, id))
          const greenLight = document.getElementById(FormatString(TRAFFIC_LIGHT_TAG_NAME.GREEN_LIGHT, id))

          if (!!redLight && !!greenLight) {
            switch (trafficLight.state) {
              case TRAFFIC_LIGHT_STATE.RED_LIGHT: {
                redLight.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[TRAFFIC_LIGHT_STATE.RED_LIGHT])
                greenLight.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[TRAFFIC_LIGHT_STATE.TURNED_OFF])
                break
              }
              case TRAFFIC_LIGHT_STATE.GREEN_LIGHT: {
                greenLight.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[TRAFFIC_LIGHT_STATE.GREEN_LIGHT])
                redLight.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[TRAFFIC_LIGHT_STATE.TURNED_OFF])
                break
              }
              default: {
                greenLight.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[TRAFFIC_LIGHT_STATE.UNKNOWN])
                redLight.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[TRAFFIC_LIGHT_STATE.UNKNOWN])
                break
              }
            }
          }
        }
      })
    }
  }

  tcoSubSectionChange(svgDisplayComponent: ElementRef, tcoSubSection: TcoSubSection) {
    if (tcoSubSection) {
      tcoSubSection.subSections.forEach((subSection) => {
        const id = subSection.id.toString()
        const subSectionTag = document.getElementById(FormatString(SUB_SECTION_TAG_NAME.SUB_SECTION, id))

        if (subSectionTag) {
          const rect = document.getElementById(FormatString(SUB_SECTION_TAG_NAME.SHAPE, id))
          const text = document.getElementById(FormatString(SUB_SECTION_TAG_NAME.CODE_PLAN, id))

          if (!!rect && !!text) {
            rect.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[subSection.state])
            text.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[subSection.state])
          }
        }
      })
    }
  }

  tcoSwitchChange(svgDisplayComponent: ElementRef, tcoSwitch: TcoSwitch) {
    if (tcoSwitch) {
      tcoSwitch.switches.forEach((ts) => {
        const id = ts.id.toString()
        const switchTag = document.getElementById(FormatString(SWITCH_TAG_NAME.SWITCH, id))

        if (switchTag) {
          const left = document.getElementById(FormatString(SWITCH_TAG_NAME.LEFT, id))
          const right = document.getElementById(FormatString(SWITCH_TAG_NAME.RIGHT, id))
          const tip = document.getElementById(FormatString(SWITCH_TAG_NAME.TIP, id))

          if (!!left && !!right && !!tip) {
            switch (ts.state) {
              case SWITCH_STATE.LEFT: {
                left.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[SWITCH_STATE.OPEN])
                right.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[SWITCH_STATE.CLOSE])
                tip.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[SWITCH_STATE.ACTIVATED])
                break
              }
              case SWITCH_STATE.RIGHT: {
                right.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[SWITCH_STATE.OPEN])
                left.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[SWITCH_STATE.CLOSE])
                tip.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[SWITCH_STATE.ACTIVATED])
                break
              }
              case SWITCH_STATE.SPECIAL: {
                right.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[SWITCH_STATE.SPECIAL])
                left.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[SWITCH_STATE.SPECIAL])
                tip.setAttribute(TCO_STYLE.COLOR_SPOT, TCO_COLORS[SWITCH_STATE.SPECIAL])
                break
              }
            }
          }
        }
      })
    }
  }
}
