import { TrafficWindow } from '../../../modeles/lines'
import Phaser, { Scene } from 'phaser'
import { Platform, PlatformStateType } from '../../../../app/core/models/platform-info-traffic'
import { SelectedPlatformInfo } from '../../../../app/core/configurations/user/selected.platform.info'
import Graphics = Phaser.GameObjects.Graphics
import Image = Phaser.GameObjects.Image
import {
  PlatformTrack1ReferentialStationDisplayElement,
  PlatformTrack2ReferentialStationDisplayElement,
} from './plateform-display-elements'
import { CreatePlatformOptions, PlatformElementFactory } from './platform-element-factory'
import { PlatformElementType } from './models/platform-element.type.model'
import { PlatformDisplayText } from './platform-display-text'


export class PlatformDisplayContainer extends Phaser.GameObjects.Container {
  private readonly _trafficWindowId: string
  private readonly _id: string
  private readonly platformDisplayText: PlatformDisplayText
  private platformDisplayElement: Graphics | Image
  private platformTrack1ReferentialStationDisplayElement: PlatformTrack1ReferentialStationDisplayElement
  private platformTrack2ReferentialStationDisplayElement: PlatformTrack2ReferentialStationDisplayElement
  private _platformState: PlatformStateType = PlatformStateType.OPEN
  private readonly currentScene: Scene
  private trafficWindow: TrafficWindow
  private currentPlatformDisplay: Graphics | Image
  private  platformFactory: PlatformElementFactory
  private platformInfo: Platform

  constructor(scene: Phaser.Scene, y: number, trafficWindow: TrafficWindow) {
    super(scene, 0, y)
    this.currentScene = scene
    this.trafficWindow = trafficWindow
    this.platformFactory = new PlatformElementFactory(this.currentScene);

    if (trafficWindow.type === 'LOOP_WITH_PLATFORM') {
      this.setX(this.x + 46)
      this.setY(this.y - 46)
    }

    this._trafficWindowId = trafficWindow.id
    this.platformDisplayText = new PlatformDisplayText(scene, {
      visible: !!trafficWindow.platFormName && !!trafficWindow.platformTimeId,
    })

    this.createPlatformDisplayElement('default')
    this.createPlatformTrackReferentialStationDisplayElement(1)
    this.createPlatformTrackReferentialStationDisplayElement(2)

    this.add([this.platformDisplayElement, this.platformDisplayText])
    this.currentPlatformDisplay = this.platformDisplayElement
  }

  get id() {
    return this._id
  }

  updatePlatformDisplay(selectedChoice: SelectedPlatformInfo, platform: Platform) {
    this.platformInfo = platform;
    const isPlatformOpen = this.platformInfo.platformState === PlatformStateType.OPEN
    isPlatformOpen ? this.handlePlatformOpenState(selectedChoice) : this.handlePlatformClosedState()

    this._platformState = this.platformInfo.platformState
  }

  private createPlatformTrackReferentialStationDisplayElement(trackNumber: number) {
    const visible = !!this.trafficWindow.platFormName && !!this.trafficWindow.platformTimeId
    const options = {timeDisplayElementOption : { visible }}

    trackNumber === 1 ?
      this.platformTrack1ReferentialStationDisplayElement = this.platformFactory.createPlatformElement('track1-referential-station', options) as PlatformTrack1ReferentialStationDisplayElement :
      this.platformTrack2ReferentialStationDisplayElement = this.platformFactory.createPlatformElement('track2-referential-station', options) as PlatformTrack2ReferentialStationDisplayElement;
  }

  private createPlatformDisplayElement(type: PlatformElementType) {
    const visible = !!this.trafficWindow.platFormName && !!this.trafficWindow.platformTimeId
    const options: CreatePlatformOptions = {platform: this.platformInfo, timeDisplayElementOption: { visible }}
    this.platformDisplayElement = this.platformFactory.createPlatformElement(type, options)
  }

  private handlePlatformOpenState(selectedChoice: SelectedPlatformInfo): void {
    this.updateReferentialTrackDisplay()
    this.platformDisplayText.updateTextDisplay(selectedChoice, this.platformInfo)
    this.updatePlatformDisplayElements(selectedChoice)
  }

  private updatePlatformDisplayElements(selectedChoice: SelectedPlatformInfo): void {
    switch (selectedChoice) {
      case SelectedPlatformInfo.TARGET:
        this.replacePlatform('target')
        break
      case SelectedPlatformInfo.ELAPSED:
        this.replacePlatform('elapsed')
        break
      default:
        this.replacePlatform('default')
        break
    }
  }

  private replacePlatform(type: PlatformElementType) {
    this.createPlatformDisplayElement(type)

    if (this.currentPlatformDisplay !== this.platformDisplayElement) {
      this.replace<Phaser.GameObjects.GameObject>(this.currentPlatformDisplay, this.platformDisplayElement, true)
      this.currentPlatformDisplay = this.platformDisplayElement
    }
  }

  private handlePlatformClosedState(): void {
    this.platformDisplayText.updateTextDisplay(SelectedPlatformInfo.NONE)
    this.replacePlatform('closed')
  }

  private updateReferentialTrackDisplay(): void {
    if (this.platformInfo.isReferentialStation) {
      if (this.platformInfo.track === 1) {
        this.add(this.platformTrack1ReferentialStationDisplayElement)
      }
      if (this.platformInfo.track === 2) {
        this.add(this.platformTrack2ReferentialStationDisplayElement)
      }
    } else {
      this.remove(this.platformTrack1ReferentialStationDisplayElement)
      this.remove(this.platformTrack2ReferentialStationDisplayElement)
    }
  }


}
