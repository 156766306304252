import 'phaser'

export enum SvgImages {
  // Normalized track images
  NORMALIZED_TRACK_CDV = 'assets/svgs/normalized_tracks/inter.svg',
  NORMALIZED_PLATFORM = 'assets/svgs/normalized_tracks/platform.svg',
  NORMALIZED_SPLIT_SWITCH_LEFT_STRAIGHT_DOWN = 'assets/svgs/normalized_tracks/split_switch_left_straight_down.svg',
  NORMALIZED_SPLIT_SWITCH_LEFT_STRAIGHT_UP = 'assets/svgs/normalized_tracks/split_switch_left_straight_up.svg',
  NORMALIZED_END_TRACK = 'assets/svgs/normalized_tracks/end_track.svg',
  NORMALIZED_SPLIT_SWITCH_LEFT_NO_CHANGE = 'assets/svgs/normalized_tracks/split_switch_left_track_1.svg',
  NORMALIZED_SPLIT_SWITCH_LEFT_NO_CHANGE_UP = 'assets/svgs/normalized_tracks/split_switch_left_track_2.svg',

  // Old track images
  TRACK_CDV = 'assets/svgs/tracks/inter.svg',
  TRACK_CDV_TIME = 'assets/svgs/tracks/inter_time.svg',
  FIRST_CDV = 'assets/svgs/tracks/inter.svg',
  LOOP_WITH_PLATFORM = 'assets/svgs/tracks/loop_with_platform.svg',
  LOOP_RIGHT = 'assets/svgs/tracks/loop_right.svg',
  LOOP_LEFT = 'assets/svgs/tracks/loop_left.svg',
  TRACK_SWITCH = 'assets/svgs/tracks/track_switch.svg',
  TRACK_SWITCH_UP = 'assets/svgs/tracks/track_switch_up.svg',
  TRACK_SWITCH_SMALL_RIGHT = 'assets/svgs/tracks/track_switch_small_right.svg',
  TRACK_SWITCH_SMALL_LEFT = 'assets/svgs/tracks/track_switch_small_left.svg',
  PLATFORM_SWITCH_SWITCH = 'assets/svgs/tracks/inter.svg',
  PLATFORM = 'assets/svgs/tracks/platform.svg',
  PLATFORM_SWITCH_DOWN = 'assets/svgs/tracks/double-platform-down-left.svg',
  PLATFORM_SWITCH_RIGHT_NO_CHANGE = 'assets/svgs/tracks/double-platform-down-right.svg',
  PLATFORM_SWITCH_UP = 'assets/svgs/tracks/double-platform-top-left.svg',
  PLATFORM_SWITCH_TRACK_1_LEFT = 'assets/svgs/tracks/double-platform-down-left.svg',
  PLATFORM_SWITCH_TRACK_1_RIGHT = 'assets/svgs/tracks/double-platform-down-right.svg',
  PLATFORM_SWITCH_TRACK_2_LEFT = 'assets/svgs/tracks/double-platform-top-left.svg',
  PLATFORM_SWITCH_TRACK_2_RIGHT = 'assets/svgs/tracks/double-platform-top-right.svg',
  PLATFORM_SWITCH_ONE_WAY_TRACK = 'assets/svgs/tracks/double_platform_down_one_way_track.svg',
  PLATFORM_SWITCH_WITH_TIME_LEFT = 'assets/svgs/tracks/double_platform_down_with_time_left.svg',
  PLATFORM_SWITCH_WITH_TIME_RIGHT = 'assets/svgs/tracks/double_platform_down_with_time_right.svg',
  PLATFORM_WITH_CORRESPONDENCE = 'assets/svgs/tracks/platform_correspondance.svg',

  SPLIT_SWITCH_RIGHT = 'assets/svgs/tracks/split_switch_right_track_1.svg',
  SPLIT_SWITCH_RIGHT_UP = 'assets/svgs/tracks/split_switch_right_track_2.svg',
  SPLIT_SWITCH_LEFT = 'assets/svgs/tracks/split_switch_left_track_1.svg',
  SPLIT_SWITCH_LEFT_NO_CHANGE = 'assets/svgs/tracks/split_switch_left_track_1.svg',
  SPLIT_SWITCH_LEFT_NO_CHANGE_UP = 'assets/svgs/tracks/split_switch_left_track_2.svg',
  SPLIT_SWITCH_LEFT_UP = 'assets/svgs/tracks/split_switch_left_track_2.svg',
  SPLIT_SWITCH_LEFT_UP_NO_CHANGE = 'assets/svgs/tracks/split_switch_left_track_2.svg',

  SPLIT_SWITCH_SINGLE_LEFT_TRACK_1 = 'assets/svgs/tracks/split_switch_single_left_track_1.svg',
  SPLIT_SWITCH_SINGLE_LEFT_TRACK_2 = 'assets/svgs/tracks/split_switch_single_left_track_2.svg',
  SWITCH_ONE_WAY_TRACK_1_RIGHT = 'assets/svgs/tracks/switch_one_way_track_down_right.svg',
  SWITCH_ONE_WAY_TRACK_2_RIGHT = 'assets/svgs/tracks/switch_one_way_track_top_right.svg',
  TRIPLE_PLATFORM_SWITCH_TRACK_2_LEFT = 'assets/svgs/tracks/triple_platform_switch_left_top.svg',
  TRIPLE_PLATFORM_SWITCH_TRACK_2_RIGHT = 'assets/svgs/tracks/triple_platform_switch_right_top.svg',
  TRIPLE_PLATFORM_SWITCH_TRACK_1_LEFT = 'assets/svgs/tracks/triple_platform_switch_bottom_left.svg',
  TRIPLE_PLATFORM_SWITCH_TRACK_1_RIGHT = 'assets/svgs/tracks/triple_platform_switch_bottom_right.svg',
  END_TRACK = 'assets/svgs/tracks/end_track.svg',
  START_TRACK = 'assets/svgs/tracks/end_track.svg',
  START_TRACK_SWITCH_PAD = 'assets/svgs/tracks/end_track_split_switch.svg',
  START_TRACK_SWITCH = 'assets/svgs/tracks/end_track.svg',
  START_TRACK_DOUBLE_PLATFORM = 'assets/svgs/tracks/end_track.svg',

  METRO_RIGHT = 'assets/svgs/trains/metro_white_right.svg',
  METRO_LEFT = 'assets/svgs/trains/metro_white_left.svg',

  RER_RIGHT = 'assets/svgs/trains/rer_white_right.svg',
  RER_LEFT = 'assets/svgs/trains/rer_white_left.svg',

  EARLY_TRAIN_CHIP = 'assets/svgs/delay-chip/rec_early.svg',
  LATE_TRAIN_CHIP = 'assets/svgs/delay-chip/rec_late.svg',
  LATE_TRAIN_MOST_DELAYED_CHIP = 'assets/svgs/delay-chip/rec_latest.svg',

  PLATFORM_CLOSED = 'assets/svgs/platform/closed.svg',
  REFERENTIAL_STATION = 'assets/svgs/platform/referential-station.svg',
  TARGET_BACKGROUND = 'assets/svgs/platform/timer-background.svg',
  TARGET_DEFAULT = 'assets/svgs/platform/waiting-time-threshold-default.svg',
  TARGET_WARNING = 'assets/svgs/platform/waiting-time-threshold-warning.svg',
  TARGET_DANGER = 'assets/svgs/platform/waiting-time-threshold-danger.svg',
  TARGET_CLOSED = 'assets/svgs/platform/waiting-time-threshold-closed.svg',

  LIVE_EVENT_AGENT = 'assets/svgs/live-events/agent.svg',
  LIVE_EVENT_INCIDENT = 'assets/svgs/live-events/incident.svg',
  LIVE_EVENT_BOTH = 'assets/svgs/live-events/agent_and_incident.svg',
}

export type SvgImagesType =
  'METRO_RIGHT'
  | 'METRO_LEFT'
  | 'RER_RIGHT'
  | 'RER_LEFT'
  | 'EARLY_TRAIN_CHIP'
  | 'LATE_TRAIN_CHIP'
  | 'LATE_TRAIN_MOST_DELAYED_CHIP'

export type PlatformSvgImagesType = 'PLATFORM_CLOSED' | 'REFERENTIAL_STATION | TARGET_BACKGROUND'

export class ImageSvgManager {
  constructor(scene: Phaser.Scene) {
    Object.keys(SvgImages).forEach((k) => {
      scene.load.svg(k, SvgImages[k])
    })
  }

  static getNameOfImage(svgImageValue: string): string | undefined {
    return Object.keys(SvgImages).find((key) => SvgImages[key] === svgImageValue)
  }
}
