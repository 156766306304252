import { AgentDto, IncidentDto } from './live-event'

export class PlatformInfoTraffic {
  lineName: string
  platforms: Platform[]
  agents: AgentDto[]
  incidents: IncidentDto[]
}

export class Platform {
  windowNumber: string
  timeToWait: number
  elapsedTime: number
  waitingTimeTarget: number
  waitingTimeThreshold: WaitingTimeThreshold
  isReferentialStation: boolean
  track: number
  platformState: PlatformStateType
  trafficState: TrafficStateType
}

export enum TrafficStateType {
  ONGOING = 'ONGOING',
  INTERRUPTED = 'INTERRUPTED',
}

export enum PlatformStateType {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export type WaitingTimeThreshold = 'NORMAL' | 'WARN' | 'DANGER'