<ion-grid [class.no-padding]="!platformService.isPlatformDesktop() || !platformService.isPlatformTablet()">
  <ion-row *ngIf="!!interval">
    <ion-col>
      <ion-row class="separator interval-detail-list-second-header">
        <ion-col class="custom-padding" sizeLg="1" sizeMd="1" sizeXs="2"><span></span></ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="7">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.theoretical' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="7">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.real' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="4">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.percentage' | translate }}</span>
        </ion-col>
      </ion-row>
      <ion-row *ngFor="let line of interval.lines; let i = index; trackBy: trackByIntervalLineName" [class.white-line]="i % 2 == 0" class="separator">
        <!-- Icône de la ligne -->
        <ion-col class="custom-padding" sizeLg="1" sizeMd="1" sizeXs="2" style="text-align: center">
          <ion-icon [src]="'assets/icon/lines/' + line.lineName + '.svg'"></ion-icon>
        </ion-col>
        <!-- Valeur théorique -->
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="7">
          <span *ngIf="selectedChoice === 'TRACK_ONE'">{{ line.averageTheoreticalTrackOneInterval | customTime }}</span>
          <span *ngIf="selectedChoice === 'TRACK_TWO'">{{ line.averageTheoreticalTrackTwoInterval | customTime }}</span>
          <span *ngIf="selectedChoice === 'SUM'">{{ line.sumTheoreticalInterval | customTime }} </span>
        </ion-col>
        <!-- Valeur réelle -->
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="7">
          <span *ngIf="selectedChoice === 'TRACK_ONE'">{{ line.averageRealTrackOneInterval | customTime }}</span>
          <span *ngIf="selectedChoice === 'TRACK_TWO'">{{ line.averageRealTrackTwoInterval | customTime }}</span>
          <span *ngIf="selectedChoice === 'SUM'">{{ line.sumRealInterval | customTime }}</span>
        </ion-col>
        <!-- Pourcentage -->
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="4">
          <div *ngIf="selectedChoice === 'TRACK_ONE'" [ngClass]="line.intervalTrackOneLevelColor" class="percent">
            <span>{{ line.percentageTrackOneInterval * 100 | number: '2.0-0' }}</span
            ><span>%</span>
          </div>
          <div *ngIf="selectedChoice === 'TRACK_TWO'" [ngClass]="line.intervalTrackTwoLevelColor" class="percent">
            <span>{{ line.percentageTrackTwoInterval * 100 | number: '2.0-0' }}</span
            ><span>%</span>
          </div>
          <div *ngIf="selectedChoice === 'SUM'" [ngClass]="line.intervalTrackSumLevelColor" class="percent">
            <span>{{ line.sumPercentageInterval * 100 | number: '2.0-0' }}</span
            ><span>%</span>
          </div>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
