import { Injectable } from '@angular/core'
import 'phaser'
import { GameConfigurationService } from './game-configuration.service'
import { MainGame } from '../../../phaser/main.game'
import { GameStateService } from './services/game-state.service'
import { GameTouchStateService } from './services/game-touch-state.service'
import { MainScene } from '../../../phaser/scenes/main.scene'

@Injectable()
export class GameProviderService {
  public mainGame: MainGame
  public mainScene: MainScene

  constructor(private gameConfigurationService: GameConfigurationService) {
  }

  createPhaserGame(gameSateService: GameStateService,
                   gameTouchStateService: GameTouchStateService,
                   parentConfiguration: string): Phaser.Game {
    this.mainScene = new MainScene()
    this.mainGame = new MainGame(this.gameConfigurationService.getGameConfiguration(parentConfiguration, this.mainScene), gameSateService, gameTouchStateService)
    return this.mainGame
  }

}
